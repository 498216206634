// #region import
import { useRef, useState, useEffect, useContext, useLayoutEffect, useCallback } from "react";
import { Link } from "react-router-dom";
//import * as jQuery from 'jquery'; 
// import html2canvas from "html2canvas";
import jsPDF from 'jspdf'
// import { initTranslate } from "../../google-translate/translate";
import { CommandBarButton, IconButton, Dialog, DialogType, Stack, StackItem    } from "@fluentui/react";
 
import { ShieldLockRegular, ErrorCircleRegular,   ChevronLeft12Filled, ChevronDown16Filled, CopyRegular} from "@fluentui/react-icons";
import MediaQuery from "react-responsive";
import { SessionStorage } from "./SessionStorage";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import supersub from 'remark-supersub'
import { v4 as uuidv4 } from 'uuid';
import { setIconOptions } from '@fluentui/react/lib/Styling';
import styles from "./Chat.module.css";
import { isEmpty, isUndefined, set } from "lodash-es"; 
import OverflowDrawer from "../../assets/OverflowDrawerWhite.png"
// import CopyIcon from "../../assets/copyResp.svg"
import WidgetIcon from "../../assets/widget.png"
import NewWindowIcon from "../../assets/newWindow.svg"
import WorldIcon  from "../../assets/WorldGr.svg"
import SlidingPane from "react-sliding-pane";
import "../../customcss/custom-react-sliding-pane.css";
import "../../customcss/custom-google-translate.css";
import { Popover } from 'react-tiny-popover'
import DOMPurify from 'dompurify';
import { XSSAllowTags } from "../../constants/sanitizeAllowables";
import downloadIcon from "../../assets/downloadIcon.svg";
import feedbackIcon from "../../assets/feedback.svg";
import restorePageIcon from "../../assets/RestorePage.svg";
import lightModeIcon from "../../assets/lightMode.svg";
import darkModeIcon from "../../assets/darkMode.svg";
import {
    FeedbackEnum,    ChatMessage,    FeedbackMessage,    ConversationRequest,    FeedbackRequest,    conversationApi,    Citation,
    ToolMessageContent,    ChatResponse,    getUserInfo,    Conversation,    historyGenerate,    feedbackGenerate,    historyUpdate,
    historyLogError,    historyClear,    ChatHistoryLoadingState,    CosmosDBStatus,    ErrorMessage,    messageStatus,    faq, requestTTS, 
    refreshEnv, FrontendSettings, refreshfrontendSettings, ExecResults, AzureSqlServerExecResults, ChatAppResponse, ResponseChoice, ResponseContext
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { Feedback } from "../../components/Feedback";
import { GoogleTranslate } from "../../components/GoogleTranslate";
import { ChatHistoryPanel } from "../../components/ChatHistory/ChatHistoryPanel";
import { AppStateContext } from "../../state/AppProvider";
import { useBoolean } from "@fluentui/react-hooks";
import classnames from 'classnames'; 
import {DropDown } from "../../components/DropDown";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css'; 
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { nord } from 'react-syntax-highlighter/dist/esm/styles/prism'
// #endregion


// #region const

let chatUUID = '';
let promptTokenCount = 0;
let id = '';
let date: Date= new Date();
let dt1 = date.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit', hour12: true}) ;
let dt2 = dt1;
const sessionStorage = new SessionStorage();
let headerClass = classnames(styles.headerTitle, "notranslate");
let headerDDClass = classnames(styles.headerDD, "notranslate");
let copyRight = classnames(styles.copyRight,"notranslate")
let closeX = classnames(styles.spActionTitle, "notranslate")
let closeXOther = classnames(styles.spActionTitleOther, "notranslate")
let closeXCitation = classnames(styles.spActionTitleCitation, "notranslate")
let closeXLangModal = classnames(styles.langModalX, "notranslate")
 
// #endregion

// #region Chat
const Chat = () => {
    // #region chat const
    const [isCautionModalPanelOpen, setIsCautionModalPanelOpen] = useState<boolean>(false);
    const [isPrivacyPolicyModalPanelOpen, setIsPrivacyPolicyModalPanelOpen] = useState<boolean>(false);
    const [showWidget, setShowWidget] = useState<boolean>(false);
    const [isUserTermsModalPanelOpen, setIsUserTermsModalPanelOpen] = useState<boolean>(false);
    const [isDarkMode, setIsDarkMode] = useState<boolean>(false);
    const lastQuestionRef = useRef<string>("");
    const appStateContext = useContext(AppStateContext)
    const AUTH_ENABLED = appStateContext?.state.frontendSettings?.auth_enabled === "true" ;
    var branding = appStateContext?.state.frontendSettings?.branding;
   
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const chatStreamContainerRef = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDone, setIsDone] = useState<boolean>(true);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<Citation>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);
    const [messages, setMessages] = useState<ChatMessage[]>([])
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [clearingChat, setClearingChat] = useState<boolean>(false);
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const [showPgmMessage, setShowPgmMessage] = useState<boolean>(false);
   
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>() 
    const [isIntentsPanelOpen, setIsIntentsPanelOpen] = useState<boolean>(false)
    const [execResults, setExecResults] = useState<ExecResults[]>([])
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const clickMeButtonRef = useRef<HTMLButtonElement | undefined>();
    const [isFAQPaneOpen, setIsFAQPaneOpen] = useState(false);
    const [isNewConv, setIsNewConv] = useState(true);
    const [text1, setText1] = useState<string>("");
    const [text2, setText2] = useState<string>("");
    const [text3, setText3] = useState<string>("");
    const [text4, setText4] = useState<string>("");
    const [text5, setText5] = useState<string>("");
   
    const [showDropDown, setShowDropDown] = useState<boolean>(false);
    const [selectPgm, setSelectPgm] = useState<string>("");
    const [selectPgmCode, setSelectPgmCode] = useState<string>("");
    const [selectPrevPgm, setSelectPrevPgm] = useState<string>("");
    const [selectPrevPgmCode, setSelectPrevPgmCode] = useState<string>("");
    const [showTranslate, setShowTranslate] = useState<boolean>(false);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);   
    const [speechUrls, setSpeechUrls] = useState<(string | null)[]>([]);
    const fList = branding?.faqList ? branding?.faqList : [{ "question": "", "answer": "", "requiresAuth": "N", "authType": "", "authFields": "" }];
    const pList = branding?.pgmList ? branding?.pgmList : ["<>"];
    const showFollowupQuestions = branding?.followup_questions_enabled === "Y" ? true : false;
    const [showAuthDialogMessage, setShowAuthDialogMessage] = useState<boolean>(false);
    const [authFields, setauthFields] = useState<string>("");
    const [authFieldsVal, setauthFieldsVal] = useState<string>("");
    const [authType, setauthType] = useState<string>("");
    const [questionV, setQuestionV] = useState<string>(""); 
    const [isFaqV, setIsFAQV] = useState<string>("");

    if (pList[0] !== "<>") {
        if (selectPgm === "") {
            let r = pList[0].split("<>")
            setSelectPgm(r[0]);
            setSelectPgmCode(r[1]);
        }
    }
    
   
    
    const pgmSelection = async (pgm: string, idx:number): Promise<void> => {
        let r = pgm.split("<>")
        if (selectPgm === ""){
            setSelectPgm(r[0]);
            setSelectPgmCode(r[1]);
        }
        setSelectPrevPgm(selectPgm);
        setSelectPrevPgmCode(selectPgmCode);
        setSelectPgmCode(r[1]);
        setSelectPgm(r[0]);
        
        // if (idx>0){
        //     setShowPgmMessage(true);
        // }
      
        if (idx>0 &&  r[3] === ""){
            setStopMsgPlaying(true);
            handlePgmDialogOpen();
           
        } 
        else {
            //console.log("redirect to " + r[2])
            //console.log("config " + r[3])
            //window.location.href = r[2];
            //let response = refreshEnv(r[3]) 
            
            newChatMisc();
            //console.log("refreshing env")
            let b:FrontendSettings
            //var iframe = document.getElementsByClassName('goog-te-banner-frame')[0];
            //VIpgJd - ZVi9od - LgbsSe
            var iframe = (document?.getElementsByClassName('VIpgJd-ZVi9od-ORHb-OEVmcd')[0] as HTMLIFrameElement) 
            //console.log("iframe found")
            if (iframe) {
                //console.log("iframe found")
                //var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
                var innerDoc = iframe.contentDocument || iframe.contentWindow?.document;
                var restore_el = innerDoc?.getElementsByTagName("button");
                if (restore_el){
                    //console.log("restore_el found")
                    for (var i = 0; i < restore_el.length; i++) {
                        //console.log(restore_el[i].id)
                        if (restore_el[i].id.indexOf("restore") >= 0) {
                            //console.log("clicking restore")
                            restore_el[i].click();
                            // var close_el = innerDoc?.getElementsByClassName("goog-close-link");
                          
                            //close_el[0].click();
                            //return;
                        }
                    }
                }
            }

           
            //document.documentElement.setAttribute('lang', 'en');
            let response = await refreshfrontendSettings(r[3], document.documentElement.lang)    
            //console.log("after refreshing env")
            //console.log(response)
            // if (!isFAQPaneOpen){
            //     setIsFAQPaneOpen(true);
            // }
            setIsFAQPaneOpen(false);
            appStateContext?.dispatch({ type: 'ENV_UPDATE_FRONTEND_SETTINGS', payload: response as FrontendSettings });
            if (response !== null) {
                b = response as FrontendSettings
                branding = b.branding;
                //console.log(b.branding);
            }
 
        }
        // console.log("redirect to " + r[2])
        // console.log("config " + r[3])
        // refreshEnv(r[3]) 
        
       
    };
    const toggleDropDown = () => {
        setShowDropDown(!showDropDown);
    };
    const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
        if (event.currentTarget === event.target) {
            setShowDropDown(false);
        }
    };
    const parsePlotFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
            try {
                const execResults = JSON.parse(message.content) as AzureSqlServerExecResults;
                const codeExecResult = execResults.all_exec_results.at(-1)?.code_exec_result;
                if (codeExecResult === undefined) {
                    return null;
                }
                return codeExecResult;
            }
            catch {
                return null;
            }
            // const execResults = JSON.parse(message.content) as AzureSqlServerExecResults;
            // return execResults.all_exec_results.at(-1)?.code_exec_result;
        }
        return null;
    }
    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };
    const pgmDialogContentProps = {
        type: DialogType.close,
        title: selectPgm + " Program ",
        closeButtonAriaLabel: 'Close',
        subText: 'Coming soon! Currently in development.'
        
    };
    const pgmModalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } } 
    };  
   
    const translateModalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450} },
    };  
    // const pdfRef = useRef(null);
    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } },
    };  
    const handleCautionModalClick = () => {
        setIsCautionModalPanelOpen(!isCautionModalPanelOpen);
        
        if (isCautionModalPanelOpen){
            setTimeout(() => setText1(" "),100);
        }
        
    };
    const toggleWidget = () => {
        setShowWidget(!showWidget);
     }
    const toggleWidgetOpen = () => {
        setShowWidget(false);
        window.open(branding?.widget_url, "_blank"); 
        
    }
    const hideWidget = () => {
        setShowWidget(false);
     }
    const handleFAQClick = () => {
        setIsFAQPaneOpen(prevState => !prevState);
    };    
    const handleCautionModalPanelDismiss = () => {
        setText1("");
        setIsCautionModalPanelOpen(false);
         
    };
     
   
    
    
    //const pList = ["Louisiana - SNAP", "Louisiana - CSE"];
    const pgmInput = () => {
        //console.log(branding?.pgmList)
        let pgmList = pList;
        return pgmList;

    };
    const handlePrivacyPolicyModalClick = () => {
        setIsPrivacyPolicyModalPanelOpen(!isPrivacyPolicyModalPanelOpen);
        if (isPrivacyPolicyModalPanelOpen){
            setTimeout(() => setText2(" "),100);
        }
    };

    const handlePrivacyPolicyModalPanelDismiss = () => {
        setText2("");
        setIsPrivacyPolicyModalPanelOpen(false);
       
    };

    const handleUserTermsModalClick = () => {
        setIsUserTermsModalPanelOpen(!isUserTermsModalPanelOpen);
        if (isUserTermsModalPanelOpen){
            setTimeout(() => setText3(" "),100);
        };
    };

    const handleUserTermsModalPanelDismiss = () => {
        setText3("");
        setIsUserTermsModalPanelOpen(false);
    };
     
    const handleFeedbackModalClick = () => {
        if (appStateContext?.state.isFeedbackPanelOpen){
            setStopMsgPlaying(false);
        } else {
            setStopMsgPlaying(true);
        }
       
        sessionStorage.msgID="";
        sessionStorage.msgFB ="neutral";
        appStateContext?.dispatch({ type: 'MSGFB', payload:'N' })
        appStateContext?.dispatch({ type: 'TOGGLE_FEEDBACK_PANEL' })
        setIsCitationPanelOpen(false);
        setIsCautionModalPanelOpen(false);
        setIsPrivacyPolicyModalPanelOpen(false);
        setIsUserTermsModalPanelOpen(false);
       
    };

    const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"];
     
    const handleErrorDialogClose = () => {
        toggleErrorDialog()
      
        setTimeout(() => {
            setErrorMsg(null)
        }, 500);
    }
    const handlePgmDialogOpen = () => {
        
        setShowPgmMessage(true);
        if (showPgmMessage) {
            setTimeout(() => setText4("AAA"), 100);
        };

    }
    const handlePgmDialogClose = () => {
       setStopMsgPlaying(false);
       setText4("");
       setShowPgmMessage(false);
       setSelectPgm(selectPrevPgm);
       setSelectPgmCode(selectPrevPgmCode);
       
    }
    const onAuthFieldValChange = (e: any) => {
        var txt = ""
        console.log("new value " + e.target.value)
        txt = e.target.value? e.target.value : "";
        console.log(" here is what I entered " + txt);
        setauthFieldsVal(txt);
    };
    const handleAuthDialogOpen = () => {
         
        setShowAuthDialogMessage(true);
        if (showAuthDialogMessage) {
            setTimeout(() => setText5("AAA"), 100);
        };

    }
    const handleAuthDialogClose = () => {
         
        setStopMsgPlaying(false);
        setText5("");
        setShowAuthDialogMessage(false);
  
       
    }

    const handleTranslateDialogClose = () => {
        setStopMsgPlaying(false); 
        setShowTranslate(false); 

    }
    const handleTranslateOpen = () => {
       setStopMsgPlaying(true); 
       setIsPopoverOpen(false);
       setShowTranslate(true);
    }
    const handleTranslate  = () => {
        setStopMsgPlaying(true);
        setShowTranslate(true);
    }
    const handleChangeModeOpen = () => {
        setIsPopoverOpen(false); 
        setIsDarkMode(!isDarkMode)
    }
    
    const errorDialogClose = () => {
       setErrorMsg(null)
        
    }

    const [isMsgRenderComplete, setIsMsgRenderComplete] = useState(true);
    const [isMsgPlaying, setIsMsgPlaying] = useState(false);
    const [stopMsgPlaying, setStopMsgPlaying] = useState(false);
    const getUserInfoList = async () => { 
        if (!AUTH_ENABLED) {
            setShowAuthMessage(false);
           
            return;
        } else{
            const userInfoList = await getUserInfo();
            if (userInfoList.length === 0 && window.location.hostname !== "127.0.0.1") {
                setShowAuthMessage(true);
            }
            else {
                setShowAuthMessage(false);
            }
        }
      
         
    }
  
    const retrievePromptTokenCount = () => {
        if (!promptTokenCount) {
          promptTokenCount = Number(sessionStorage.promptTokenCount)
       }
    }

    const retrieveChatUUID = () => {
        if (lastQuestionRef.current && !chatUUID) {
          //chatUUID = uuidv4();
          chatUUID = sessionStorage.sessionUUID
       }
    }
    const onShowCitation = (citation: Citation) => {
        setActiveCitation(undefined);
        setIsCitationPanelOpen(false);
        setActiveCitation(citation);
        setIsCitationPanelOpen(true);
    };

    const onViewSource = (citation: Citation) => {
        if (citation.url && !citation.url.includes("blob.core")) {
            window.open(citation.url, "_blank");
        }
    };
    
    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const disabledButton = () => {
        // console.log("is msg render complete " + isMsgRenderComplete);
        // console.log("is chat history loaded " + appStateContext?.state.chatHistoryLoadingState);
        // console.log("is clearing chat " + clearingChat);
        // console.log("has no messages" + (messages && messages.length === 0));
        let x = (!isMsgRenderComplete) || (messages && messages.length === 0) || clearingChat || appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading
        //console.log(x);
        return x
        //return isLoading || (messages && messages.length === 0) || clearingChat || appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading
    }
 
    

    const hideFeedbackButton = () => {
         //console.log("isanscomp " + isMsgRenderComplete);
         return (!isMsgRenderComplete) || appStateContext?.state.isFeedbackPanelOpen   || (messages && messages.length === 0) || clearingChat || appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading;
       //return (isLoading) || appStateContext?.state.isFeedbackPanelOpen   || (messages && messages.length === 0) || clearingChat || appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading;
    }

    
 
    // #region clearChat
    const clearChat = async () => {
        lastQuestionRef.current = "";
        setClearingChat(true)
        setAnswers([]);
        setSpeechUrls([]);
        if(appStateContext?.state.currentChat?.id && appStateContext?.state.isCosmosDBAvailable.cosmosDB){
            let response = await historyClear(appStateContext?.state.currentChat.id,sessionStorage.sessionUUID, selectPgmCode)
            if(!response.ok){
                setErrorMsg({
                    title: "Error clearing current chat",
                    subtitle: "Please try again. If the problem persists, please contact the site administrator.",
                })
                toggleErrorDialog();
            }else{
                appStateContext?.dispatch({ type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: appStateContext?.state.currentChat.id});
                appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext?.state.currentChat});
                setActiveCitation(undefined);
                setIsCitationPanelOpen(false);
                setIsIntentsPanelOpen(false)
                setMessages([])
            }
        }
        setClearingChat(false)
    };
    // #endregion
    const tryGetRaiPrettyError = (errorMessage: string) => {
        try {
            // Using a regex to extract the JSON part that contains "innererror"
            const match = errorMessage.match(/'innererror': ({.*})\}\}/)
            if (match) {
                // Replacing single quotes with double quotes and converting Python-like booleans to JSON booleans
                const fixedJson = match[1]
                    .replace(/'/g, '"')
                    .replace(/\bTrue\b/g, 'true')
                    .replace(/\bFalse\b/g, 'false')
                const innerErrorJson = JSON.parse(fixedJson)
                let reason = ''
                // Check if jailbreak content filter is the reason of the error
                const jailbreak = innerErrorJson.content_filter_result.jailbreak
                if (jailbreak.filtered === true) {
                    reason = 'Jailbreak'
                }

                // Returning the prettified error message
                if (reason !== '') {
                    return (
                        'The prompt was filtered due to triggering Azure OpenAI’s content filtering system.\n' +
                        'Reason: This prompt contains content flagged as ' +
                        reason +
                        '\n\n' +
                        'Please modify your prompt and retry. Learn more: https://go.microsoft.com/fwlink/?linkid=2198766'
                    )
                }
            }
        } catch (e) {
            console.error('Failed to parse the error:', e)
        }
        return errorMessage
    };
    const parseErrorMessage = (errorMessage: string) => {
        let errorCodeMessage = errorMessage.substring(0, errorMessage.indexOf('-') + 1)
        const innerErrorCue = "{\\'error\\': {\\'message\\': "
        if (errorMessage.includes(innerErrorCue)) {
            try {
                let innerErrorString = errorMessage.substring(errorMessage.indexOf(innerErrorCue))
                if (innerErrorString.endsWith("'}}")) {
                    innerErrorString = innerErrorString.substring(0, innerErrorString.length - 3)
                }
                innerErrorString = innerErrorString.replaceAll("\\'", "'")
                let newErrorMessage = errorCodeMessage + ' ' + innerErrorString
                errorMessage = newErrorMessage
            } catch (e) {
                console.error('Error parsing inner error message: ', e)
            }
        }
        return tryGetRaiPrettyError(errorMessage)
    };
    function addWaterMark(doc:any) {
        var totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++ ) {
          doc.setPage(i);
          doc.setTextColor(150);
          doc.setFontSize(40);
          doc.text(50, doc.internal.pageSize.height - doc.internal.pageSize.height/2, 'Priya Chat History', {angle: 45});
         }
      
        return doc;
      }
    function addFooters(doc:any) {
        const pageCount = doc.internal.getNumberOfPages()
        doc.setTextColor(28, 27, 44);
        doc.setFont('Times', 'italic')
        doc.setFontSize(12)
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          doc.text('Page ' + String(i) + ' of ' + String(pageCount), doc.internal.pageSize.width / 2, 287, {
            align: 'center'
          })
        }
        return doc;
      }  
    function addHeaders(doc:any, convId:string, dt:string, pgm:string) {
        const pageCount = doc.internal.getNumberOfPages()
        doc.setTextColor(28, 27, 44);
        doc.setFontSize(12)
        //doc.setPage(1)
        //doc.setFont('Times')
        let msgList1 = pgm + '\nChat History ' + dt   + ' | Conversation ID: ' + convId + '\n\n';
        //doc.text(msgList1, 8, 10, { align: 'left', maxWidth: doc.internal.pageSize.width * 0.85 })
        let msgList2 = 'Cautions on relying on artificial intelligence (AI): ';
        msgList2 += branding?.caution_text.replaceAll(">", "") + '\n\n';
        //doc.setFont('Times', 'italic')
        //doc.text(msgList2, 8, 25, { align: 'left',maxWidth: doc.internal.pageSize.width * 0.85  })
        //doc.text("-".repeat(127), 8, 55, { align: 'left', maxWidth: doc.internal.pageSize.width * 0.85});
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
            doc.setFont('Times')
            doc.text(msgList1, 8, 10, { align: 'left', maxWidth: doc.internal.pageSize.width * 0.85 })
            doc.setFont('Times', 'italic')
            doc.text(msgList2, 8, 25, { align: 'left', maxWidth: doc.internal.pageSize.width * 0.85 })
            doc.text("-".repeat(127), 8, 55, { align: 'left', maxWidth: doc.internal.pageSize.width * 0.85 });
         
        }
        return doc;
      }
  
    const exportChatPDF = () => {
        setIsPopoverOpen(false);
          
        let pdf = new jsPDF('p','mm', 'a4',true);
        let dt =new Date().toLocaleString();
        let conversation: Conversation | null | undefined;
        let convId = "";
        let pgm = "";
        conversation = appStateContext?.state?.currentChat;
        if (conversation){
            convId = conversation.id;
        }
        if ( branding?.header_title !== undefined) {
            pgm = branding?.header_title;
        }
        // if (branding?.show_heater_title_beta == "Y"){
        //     pgm += " (Beta)"
        // }
        // let msgList = 'Chat History ' + dt + '\n';
        // msgList += 'Conversation ID: ' + conversation?.id + '\n\n';
        // pdf.setTextColor(28, 27, 44);
        // pdf.setFont('Times')
        // pdf.setFontSize(12)
        // pdf.text("-".repeat(500),0, 37);
        // pdf.text(msgList, 8, 41 , { maxWidth: pdf.internal.pageSize.width * 0.85 });
        let msgList=""

        if (conversation){
            let r = conversation?.messages.filter((answer) => answer.role !== ERROR && answer.role !== TOOL);
            r.forEach((item) => {
                if (item.role === 'user'){
                    msgList += 'User: ' + item.content + 'EndUser\n';
                    
                }
                else {
                    //console.log(item.content)
                    msgList += 'Priya: ' + item.content + '\n*** AI generated content ***\n\n';
                } 
            });

        }
        
        pdf.setFont('Helvetica')
        pdf.setFontSize(14)
        // pdf.text(msgList, 10, 40 , { maxWidth: pdf.internal.pageSize.width * 0.85 });
        var bodyContent = pdf.splitTextToSize(msgList, 180 );
        var pageHeight = pdf.internal.pageSize.getHeight();
        var firstPageOnly = "N";
        // console.log("ph - bcL")
        // console.log(pageHeight)
        // console.log(bodyContent.length);
        var y = 65
        for (var i = 0; i < bodyContent.length; i++) {
          if (y+15 > pageHeight) {
            if (firstPageOnly == "Y") {
                y = 15;
            } else {
                y =65;
            }
           
            pdf.addPage();
          }
          let ctr=0
          let r = bodyContent[i].split("EndUser");
          let txtHeight = 0;
          let x=[];
          let s ="";
            r.forEach((t:string) => {
                // if (ctr === 0) {
                   txtHeight = pdf.getTextDimensions(t).h;
                     if (txtHeight>0){
                     
                        if (t.includes("User:")){
                            pdf.setFont('Helvetica', 'bold');
                        } else {
                            pdf.setFont('Helvetica', 'normal');
                        
                        }
                        pdf.text(t, 8, y);
                        y += 1;
                    } else {
                        y += 1;
                    }
                
                   
                   
                // } else {
                //     console.log(t);
                //     x = t.split("User:")
                //     s = x[0];
                //     console.log(s);
                //     txtHeight = pdf.getTextDimensions(s).h;
                //     console.log(txtHeight);

                //     pdf.setFont('Helvetica');
                //     pdf.text(s, 8, y);
                //     y += txtHeight;
                //     s = x[1];
                //     txtHeight = pdf.getTextDimensions(s).h;
                //     console.log(txtHeight);
                //     pdf.setFont('Helvetica Bold');
                //     pdf.text(s, 8, y);
                //     y += txtHeight;
                // }
                ctr += 1;
            });   
          
         
        //   pdf.text(bodyContent[i],8, y );
          y = y + 7;
        }   
        
        pdf = addHeaders(pdf, convId,dt,pgm);
        pdf= addWaterMark(pdf);
        pdf = addFooters(pdf);
        pdf.save("Chat_History_"+ dt +".pdf")

    };
       
    const exportChat = () => {
        setIsPopoverOpen(false);
        //const fileData =  appStateContext?.state.chatHistory;
        let dt =new Date().toLocaleString();
        let msgList = 'Chat History ' + dt + '\n\n';
        let conversation: Conversation | null | undefined;
        conversation = appStateContext?.state?.currentChat;
        if (conversation){
            let r = conversation?.messages.filter((answer) => answer.role !== ERROR && answer.role !== TOOL);
            r.forEach((item) => {
                if (item.role === 'user'){
                    msgList += 'User: ' + item.content + '\n';
                }
                else {
                    msgList += 'Priya: ' + item.content + '\n\n';
                } 
            });

        }
       
        const blob = new Blob([msgList], { type: "text/plain" });
        //const blob = new Blob([fileData], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
         
        //link.download = "Chat_History_"+ dt +".txt";
        link.download = "Chat_History_"+ dt +".txt";
        link.href = url;
        link.click();
      }
    // #region newChat
    const newChatMisc = () => {
        if (isMsgPlaying) {
            //console.log("***stop msg playing and new chat")
            setStopMsgPlaying(true);
            setTimeout(() => newChat(), 100);
        }
        else {
            //console.log("***new chat")
            newChat();
        }
        
        
    };
    const newChat = () => {
       // console.log(" New chat ")
        //console.log("STM " + stopMsgPlaying)
        setProcessMessages(messageStatus.Processing)
        setMessages([]);
        setAnswers([]);
        setSpeechUrls([]);
        setIsCitationPanelOpen(false);
        setActiveCitation(undefined);
        setIsIntentsPanelOpen(false);
        
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
        let date: Date= new Date();
        dt1 = date.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit', hour12: true}) ;
        setIsNewConv(true);
        setProcessMessages(messageStatus.NotRunning)
        
        setStopMsgPlaying(false);
        //console.log("STM a " + stopMsgPlaying)
    };
    // #endregion
    const onShowExecResult = () => {
        setIsIntentsPanelOpen(true)
    }
    // #region stopGenerating
    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        //console.log("stop gen false")
        setIsLoading(false);
    }
    // #endregion

    // #region processResultMessage    
    let assistantMessage = {} as ChatMessage
    let toolMessage = {} as ChatMessage
    let assistantContent = ""
    let assistantContext ={}  as ResponseContext
    let assistantContextO = {} as ResponseContext
    const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string) => {
        if (resultMessage.content.includes('all_exec_results')) {
            const parsedExecResults = JSON.parse(resultMessage.content) as AzureSqlServerExecResults
            setExecResults(parsedExecResults.all_exec_results)
        }
        if (resultMessage.role === ASSISTANT) {
           
            assistantContent += resultMessage.content
            assistantMessage = resultMessage
            assistantMessage.content = assistantContent
            assistantMessage.feedbackOnMsg = FeedbackEnum.Neutral
            if (resultMessage.context !== undefined){
                //console.log("assistant" + resultMessage.context)
                assistantContext= resultMessage.context 
                //console.log("assistant follow up" + assistantContext.followup_questions)
                assistantMessage.context = assistantContext
            }
        }

        if (resultMessage.role === TOOL){
            toolMessage = resultMessage
        } 

        if (!conversationId) {
            isEmpty(toolMessage) ?
                setMessages([...messages, userMessage, assistantMessage]) :
                setMessages([...messages, userMessage, toolMessage, assistantMessage]);
        } else {
            isEmpty(toolMessage) ?
                setMessages([...messages, assistantMessage]) :
                setMessages([...messages, toolMessage, assistantMessage]);
        }
    };
    // #endregion

    // #region makeApiRequestWithoutCosmosDB
    const makeApiRequestWithoutCosmosDB = async (question: string, conversationId?: string) => {
     
    };
    // #endregion 
    
    // #region makeApiRequest
    const makeApiRequestAuthValidate = async () => {
        handleAuthDialogClose();
        let conversationId = appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined
        makeApiRequest(questionV, isFaqV, "Y",conversationId);
    }

    const makeApiRequestAuth = async (question: string, isFaq: string, answer: string, requiresAuth: string, authType: string, authFields: string) => {
        // request for information if requiresAuth = "Y"
        
        if (requiresAuth === "Y") {
            //show dialog with text, label and textbox for each field in authFields
            setauthFields(authFields);
            setauthType(authType);
            setQuestionV(question);
            setIsFAQV(isFaq);
            handleAuthDialogOpen();
            //let conversationId = appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined
           // makeApiRequest(question, isFaq, conversationId);
        }
        else {
          
            setauthFields("");
            setauthType("");
            setauthFieldsVal("");
            makeApiRequestAfterClear(question, isFaq, requiresAuth);
        }
        
    }

    const makeApiRequestAfterClear = async (question: string, isFaq: string, requiresAuth: string) => {
      //  newChat();
        let conversationId = appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined
     
        makeApiRequest(question, isFaq, requiresAuth,conversationId);
    }

    const languageNames = new Intl.DisplayNames(['en'], {
        type: 'language'
    });
    
    const makeApiRequest = async (question: string,isFaq:string, requiresAuth: string, conversationId?: string) => {
        setStopMsgPlaying(true);
        let lan = ! isUndefined(languageNames.of(document.documentElement.lang)) ? languageNames.of(document.documentElement.lang) : "English";
        
        let lang = "English"
        if (! isUndefined(lan)){
            lang = lan;
        }
         
        setActiveCitation(undefined);
        setIsCitationPanelOpen(false);
        
        if ((Math.max(window.screen.width, window.innerWidth) <= 768) || (window !== window.parent)) {
            //console.log("window.screen.width: " + window.screen.width);
            //console.log("window.innerWidth: " + window.innerWidth);
            setIsFAQPaneOpen(false);
        
        } 
        lastQuestionRef.current = question;
        //console.log("start   -true")
        setIsDone(false);
        setIsLoading(true);
        setShowLoadingMessage(true);
        retrieveChatUUID();
        retrievePromptTokenCount()
        //set answer complete to false
        setIsMsgRenderComplete(false);
        let manswers = answers;
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        //console.log("********COSMOS DB*****")
        //console.log(chatUUID)
        const userMessage: ChatMessage = {
            id: uuidv4(),
            role: "user",
            content: question,
            context: { followup_questions: null },
            date: new Date().toISOString(),
            prompt_tokens: promptTokenCount,
            completion_tokens: 0,
           
        };

        //api call params set here (generate)
        let request: ConversationRequest;
        let conversation;
        
        if(conversationId){
            //console.log("############## FOUND conversation ID ################")
            //console.log(conversationId)
            conversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
            if(!conversation){
                //console.log("NO CONVERSATION FOUND")
                console.error("Conversation not found.");
              
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            }else{
                //console.log("CONVERSATION FOUND")
                conversation.messages.push(userMessage);
                request = {
                    messages: [...conversation.messages.filter((answer) => answer.role !== ERROR)],
                    //sessionUUID: sessionStorage.sessionUUID,
                    chatUUID,
                };
            }
        }else{
            //console.log("######NO CONVERSATION ID FOUND######")
            request = {
                messages: [userMessage].filter((answer) => answer.role !== ERROR),
                //sessionUUID: sessionStorage.sessionUUID,
                chatUUID,
            };
            setMessages(request.messages)
        }
        let result = {} as ChatResponse;
        var errorResponseMessage = "Please try again. If the problem persists, please contact the site administrator.";
        let ans = ""
        try {
            const response = conversationId ? 
                requiresAuth === "Y" ? 
                    await historyGenerate(request, abortController.signal, chatUUID, lang, isFaq, selectPgmCode, authType, authFieldsVal, conversationId) : 
                    await historyGenerate(request, abortController.signal, chatUUID, lang, isFaq, selectPgmCode, "", "", conversationId) : 
                requiresAuth === "Y" ? 
                    await historyGenerate(request, abortController.signal, chatUUID, lang, isFaq, selectPgmCode, authType, authFieldsVal):
                    await historyGenerate(request, abortController.signal, chatUUID, lang, isFaq, selectPgmCode, "", "");
            
            if(!response?.ok){
                //console.log("err on resp")
                const responseJson = await response.json();
                errorResponseMessage = responseJson.error === undefined ? errorResponseMessage : parseErrorMessage(responseJson.error);
                let errorChatMsg: ChatMessage = {
                    id: uuidv4(),
                    role: ERROR,
                    content: `There was an error generating a response. Chat history can't be saved at this time. ${errorResponseMessage}`,
                    context: { followup_questions: null },
                    date: new Date().toISOString(),
                    prompt_tokens: 0,
                    completion_tokens: 0,
                   
                }
                let resultConversation;
              
                //console.log(conversationId)
                //conversationId = x
                if(conversationId){
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if(!resultConversation){
                        console.error("####Conversation not found.###");
                        console.error("Conversation not found.");
                       
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation.messages.push(errorChatMsg);
                }else{
                   
                    setMessages([...messages, userMessage, errorChatMsg])
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                setMessages([...resultConversation.messages]);
                return;
            }
            if (response?.body) {
                //console.log("BBB");
                const reader = response.body.getReader();
                
                let runningText = "";
                while (true) {
                    //console.log("makeapirequestwithcosmos 1");
                    setProcessMessages(messageStatus.Processing)
                    const {done, value} = await reader.read();
                    if (done) {break;}
                  
                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            //console.log("obj: " + obj)
                            if (obj !== "" && obj !== "{}") {
                                runningText += obj;
                                result = JSON.parse(runningText);
                                if (result.choices?.length > 0) {
                                    result.choices[0].messages.forEach((msg) => {
                                        //console.log("msg: " + msg)
                                        if (msg.content !="[DONE]") { 
                                            msg.id = uuidv4();
                                            msg.date = new Date().toISOString();
                                            // if (msg.role === ASSISTANT) {
                                            //     msgLen += msg.content.length;
                                            // }
                                            //console.log("msg content: "+  msg.content )
                                            if (msg.role === ASSISTANT){
                                                ans += msg.content
                                            }
                                            processResultMessage(msg, userMessage, conversationId);
                                        }
                                    })
                                }
                                runningText = "";
                                if (result.choices[0].messages?.some(m => m.role === ASSISTANT && m.content !== "[DONE]")) {
                                    setShowLoadingMessage(false);
                                    // setTimeout(() => {
                                    //     setShowLoadingMessage(false);
                                    // }, 600);
                                }
                             
                            }
                        }
                        catch (e) {
                            if (!(e instanceof SyntaxError)) {
                                console.error(e);
                                throw e;
                            } else {
                                console.log("Incomplete message. Continuing...")
                            }
                         }
                    });
                }
                //console.log("Chat 1 Comes here "  + result.isFaq);
                //undefined means regular Extension flow result
                if (isUndefined(result.isFaq)){
                    result.isFaq = "No"
                }
                //if the user typed in a FAQ question
                if (result.isFaq === "Yes" && result.isFaqUserMessage === "N"){
                    userMessage["isFaq"] = "Typed"
                }
                assistantMessage["isFaq"] = result.isFaq
                
                let resultConversation;
                //console.log("old conv id " + conversationId);
                //console.log("new conv id " + result.history_metadata.conversation_id);

                if (conversationId != result.history_metadata.conversation_id) {
                    //console.log("set the conv id to null");
                    setAnswers([]);
                    setSpeechUrls([]);
                    setMessages([]);
                    manswers=[]
                    conversationId = undefined;
                    let date: Date= new Date();
                    dt1 = date.toLocaleTimeString([], {hour: 'numeric', minute: '2-digit', hour12: true}) ;
                    setIsNewConv(true);
                }
                else {
                   dt2 = dt1;
                   setIsNewConv(false);
                }

                if(conversationId){
                    //console.log("or Comes here");
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if(!resultConversation){
                        console.error("Conversation not found.");
                        
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    } 
                    isEmpty(toolMessage) ?
                        resultConversation.messages.push(assistantMessage) :
                        resultConversation.messages.push(toolMessage, assistantMessage)
                }else{
                    
                    resultConversation = {
                        id: result.history_metadata.conversation_id,
                        title: result.history_metadata.title,
                        messages: [userMessage],
                        date: result.history_metadata.date
                    }
                    isEmpty(toolMessage) ?
                        resultConversation.messages.push(assistantMessage) :
                        resultConversation.messages.push(toolMessage, assistantMessage)
                }
                if(!resultConversation){
                   
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
              
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                isEmpty(toolMessage) ?
                    setMessages([...messages, assistantMessage]) :
                    setMessages([...messages, toolMessage, assistantMessage]);     
            }
            
        } catch ( e )  {
            if (!abortController.signal.aborted) {
                let errorMessage = `An error occurred. ${errorResponseMessage}`;
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                errorMessage = parseErrorMessage(errorMessage);
                let errorChatMsg: ChatMessage = {
                    id: uuidv4(),
                    role: ERROR,
                    content: errorMessage,
                    context: { followup_questions: null },
                    date: new Date().toISOString(),
                    prompt_tokens: 0,
                    completion_tokens: 0 
                }
                let resultConversation;
                if(conversationId){
                    resultConversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
                    if(!resultConversation){
                        console.error("Conversation not found.");
                       
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation.messages.push(errorChatMsg);
                }else{
                    if(!result.history_metadata){
                        console.error("Error retrieving data.", result);
                        
                        setIsLoading(false);
                        setShowLoadingMessage(false);
                        abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                        return;
                    }
                    resultConversation = {
                        id: result.history_metadata.conversation_id,
                        title: result.history_metadata.title,
                        messages: [userMessage],
                        date: result.history_metadata.date
                    }
                    resultConversation.messages.push(errorChatMsg);
                }
                if(!resultConversation){
                    
                    setIsLoading(false);
                    setShowLoadingMessage(false);
                    abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                    return;
                }
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: resultConversation });
                setMessages([...messages, errorChatMsg]);
            } else {
                setMessages([...messages, userMessage])
            }
        } finally {
            //console.log("fin -false")
            
            let  hasToolMsg ="N";
            if (! isEmpty(toolMessage)){
                hasToolMsg = "Y";
            }
            let rc: ResponseChoice = { index: 0, message: ans, has_toolmsg: hasToolMsg };
            let cr: ChatAppResponse = { choices: [rc] };
            //console.log(answers)
            //console.log(manswers)
            setAnswers([...manswers, [question, cr]]);
             
            setIsDone(true);
            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
            setStopMsgPlaying(false);
            // if (branding?.text_to_speech_enabled === "Y") {
            //     let r = await requestTTS(ans);
            //      //= conversationId ? await requestTTS(ans, abortController.signal, chatUUID, selectPgmCode, conversationId) : await requestTTS(ans, abortController.signal, chatUUID, selectPgmCode);
                
            //      if (r){
            //         //let audio = new Audio(r)
            //         //await audio.play()
            //      }
            // }
           
        }
        return abortController.abort();

    }
    // #endregion

    // #region sendFeedback
    const sendFeedback = async (rating: number, feedback: string, conversationId?: string, messageId?:string, messageFb?:string) => {
         
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);
        let cId = '';
        
        //api call params set here (generate)
        let request: FeedbackRequest;
        let conversation;
        if(conversationId){
            cId = conversationId
            
            conversation = appStateContext?.state?.chatHistory?.find((conv) => conv.id === conversationId)
            if(!conversation){
             
                console.error("Conversation not found.");
              
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } 
        }else{
            console.error("Conversation not found.");
            setIsLoading(false);
        }
        let result = {} as ChatResponse;
        try {
            const feedbackMsgv: FeedbackMessage = {
                rating: rating,
                feedback: feedback,
                assistantMessageId: messageId,
                feedbackMsg: messageFb,
            };
            request = {
                message: [feedbackMsgv],
                chatUUID,
            };
            
            const response =   await feedbackGenerate(request, abortController.signal, chatUUID, cId, selectPgmCode);
            if(!response?.ok){
                console.log("There was an error saving feedback.  If the problem persists, please contact the site administrator.")
                return;
            }
           
            
        } catch ( e )  {
            if (!abortController.signal.aborted) {
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            }
        } finally {
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            appStateContext?.dispatch({ type: 'TOGGLE_FEEDBACK_PANEL' })
        }
        return abortController.abort();

    }
    // #endregion
    
    // #endregion
 
    setIconOptions({
        disableWarnings: true
    });
    
    // #region useEffect
    // useEffect(() => {
    //     // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    //     const remove = initTranslate();
    
    //     return remove;
    //   }, []);
    useEffect(() => {
        const getUrl = async (msg: string) => {
            let response: string | null = null;
            if (msg !== "") {
                //console.log(msg)
                response = await requestTTS(msg);
            }
            return response
        }
        if (answers && branding?.text_to_speech_enabled === "Y") {
//            console.log("answers get url: ", answers);
            let mspeechUrls = speechUrls;
            // For each answer that is missing a speech URL, fetch the speech URL
           
            let tctr=0;
            for (let i = 0; i < answers.length; i++) {
                //question
                if (!mspeechUrls[tctr]) {
                    mspeechUrls = [...mspeechUrls.slice(0, tctr), tctr.toString(), ...mspeechUrls.slice(tctr + 1)];
                    //setSpeechUrls([...speechUrls.slice(0, tctr), "null", ...speechUrls.slice(tctr + 1)]);
                }
                tctr += 1;
                //console.log("speechUrls 1: ", mspeechUrls);

                //tool?
                if (answers[i][1].choices[0].has_toolmsg === "Y") {
                    if (!mspeechUrls[tctr]) {
                        mspeechUrls = [...mspeechUrls.slice(0, tctr), tctr.toString(), ...mspeechUrls.slice(tctr + 1)];
                        //setSpeechUrls([...speechUrls.slice(0, tctr), "null", ...speechUrls.slice(tctr + 1)]);
                    }
                    tctr += 1;
                }
                //console.log("speechUrls 2: ", mspeechUrls);
                //console.log("before the answer for " + i + " tctr=" + tctr)
                //answer
                if (!mspeechUrls[tctr]) {
                    
                    //  requestTTS(answers[i][1].choices[0].message).then(speechUrl => {
                    //     console.log("C " + speechUrl)
                    //     setSpeechUrls([...speechUrls.slice(0, i), speechUrl, ...speechUrls.slice(i + 1)]);
                    // });
                    let r = getUrl(answers[i][1].choices[0].message).then(speechUrl => {
                        mspeechUrls =[...mspeechUrls.slice(0, tctr), speechUrl, ...mspeechUrls.slice(tctr + 1)];
                        //console.log("speech url for " + tctr + " " +speechUrl)
                        setSpeechUrls(mspeechUrls);
                        
                     });
                }
                tctr += 1;
            }
            setSpeechUrls(mspeechUrls);
            //console.log("speechUrls: ", speechUrls);
        }
    }, [answers]);
    useEffect(() => {
        if(appStateContext?.state.isCosmosDBAvailable?.status === CosmosDBStatus.NotWorking && appStateContext.state.chatHistoryLoadingState === ChatHistoryLoadingState.Fail && hideErrorDialog){
            let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`
            setErrorMsg({
                title: "Chat history is not enabled",
                subtitle: subtitle
            })
            toggleErrorDialog();
        }
    }, [appStateContext?.state.isCosmosDBAvailable]);

    
    useEffect(() => {
        setIsLoading(appStateContext?.state.chatHistoryLoadingState === ChatHistoryLoadingState.Loading)
     }, [appStateContext?.state.chatHistoryLoadingState])

  
 
    useEffect(() => {
        if (appStateContext?.state.currentChat) {

            setMessages(appStateContext.state.currentChat.messages)
        }else{
            setMessages([])
        }
    }, [appStateContext?.state.currentChat]);

    useEffect(() => {
        if (!lastQuestionRef.current) {
          chatUUID = '';
        }
      }, [lastQuestionRef.current]);

    useEffect(() => {
        //     if (AUTH_ENABLED !== undefined) getUserInfoList();
    // }, [AUTH_ENABLED]);
      //prevent flash of auth message onload
      const timer = setTimeout(() => {
      if (AUTH_ENABLED === undefined) {
          setShowAuthMessage(false);
        }
      }, 2000);
      if (AUTH_ENABLED !== undefined) {
        clearTimeout(timer);
        if (!AUTH_ENABLED) return;
        getUserInfoList();
      }
      return () => clearTimeout(timer);}, [AUTH_ENABLED]);
    useLayoutEffect(() => {
        const saveToDB = async (messages: ChatMessage[], id: string) => {
            //console.log("saveTODB: this is my chat UUID: " +  chatUUID)
            //console.log("saveTODB: convId: " +  id)
            const response = await historyUpdate(messages, id, chatUUID, selectPgmCode)
            return response
        }

        const logErrorToDB = async (message: ChatMessage, id: string) => {
            //console.log("logErrorToDB: this is my chat UUID: " +  chatUUID)
            const response = await historyLogError(message, id, chatUUID,selectPgmCode)
            return response
        }
        //console.log("in use layout effect " + processMessages)
        //console.log("current chat: " + appStateContext?.state.currentChat)
        if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
                if(appStateContext.state.isCosmosDBAvailable.cosmosDB){
                    if(!appStateContext?.state.currentChat?.messages){
                        console.error("Failure fetching current chat state.")
                        return 
                    }
                    id= appStateContext.state.currentChat.id
                    //console.log("save db")
                    saveToDB(appStateContext.state.currentChat.messages, appStateContext.state.currentChat.id)
                    .then((res) => {
                        if(!res.ok){
                            let errorMessage = "An error occurred. Answers can't be saved at this time. If the problem persists, please contact the site administrator.";
                            let errorChatMsg: ChatMessage = {
                                id: uuidv4(),
                                role: ERROR,
                                content: errorMessage,
                                context: { followup_questions: null },
                                date: new Date().toISOString(),
                                prompt_tokens: 0,
                                completion_tokens: 0 
                            }
                            logErrorToDB(errorChatMsg,id)
                            if(!appStateContext?.state.currentChat?.messages){
                                let err: Error = {
                                    ...new Error,
                                    message: "Failure fetching current chat state."
                                }
                                throw err
                            }
                            setMessages([...appStateContext?.state.currentChat?.messages, errorChatMsg])
                        } 
                        
                        return res as Response
                    })
                    .catch((err) => {
                        console.error("Error: ", err)
                        let errorChatMsg: ChatMessage = {
                            id: uuidv4(),
                            role: ERROR,
                            content: err,
                            context: { followup_questions: null },
                            date: new Date().toISOString(),
                            prompt_tokens: 0,
                            completion_tokens: 0 
                        }
                        logErrorToDB(errorChatMsg,id)
                        let errRes: Response = {
                            ...new Response,
                            ok: false,
                            status: 500,
                        }
                        return errRes;
                    })
                }else{
                }
                appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext.state.currentChat });
                setMessages(appStateContext.state.currentChat.messages)
            setProcessMessages(messageStatus.NotRunning)
        }
    }, [processMessages]);

    // useLayoutEffect(() => {
    //     chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    // }, [showLoadingMessage, processMessages]);
    // useLayoutEffect(() => {

    //     const observer = new MutationObserver(() => {

    //         scrollToEnd();
    //       });

    //       if (chatStreamContainerRef?.current) {
    //         observer.observe(chatStreamContainerRef.current, {
    //           childList: true,
    //           subtree: true,
    //         });
    //       }

    //       return () => {
    //         observer.disconnect();
    //       };
    //     //chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    // }, [showLoadingMessage, processMessages, isLoading, !isMsgRenderComplete]);

    useEffect(() => {
        

        const observer = new MutationObserver(() => {
          
          scrollToEnd();
        });
    
        if (chatStreamContainerRef?.current) {
          observer.observe(chatStreamContainerRef.current, {
            childList: true,
            subtree: true,
          });
        }
    
        return () => {
          observer.disconnect();
        };
      }, [chatStreamContainerRef?.current]);

    const scrollToEnd = useCallback(() => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
        
      }, [chatMessageStreamEnd]);
    const [copyStatus, setCopyStatus] = useState(false);
    const copyToClipboard = (msg:string) => {
        navigator.clipboard.writeText(msg);
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000);
    };
    // #endregion 

    return (
        <div className={styles.container} role="main" >
             <a href="#main" className={styles.skipToMain} aria-label="Skip to main content">Skip to main content</a>
            {showAuthMessage ? (
                <Stack className={styles.chatEmptyState}>
                    <ShieldLockRegular className={styles.chatIcon} style={{color: 'darkorange', height: "200px", width: "200px"}}/>
                    <h1 className={styles.chatEmptyStateTitle}>Authentication Not Configured</h1>
                    <h2 className={styles.chatEmptyStateSubtitle}>
                        This app does not have authentication configured. Please add an identity provider by finding your app in the 
                        <a aria-label="Azure Portal" href="https://portal.azure.com/" target="_blank"> Azure Portal </a>
                        and following 
                        <a aria-label="Microsoft Authentication"  href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization" target="_blank"> these instructions</a>.
                    </h2>
                    <h2 className={styles.chatEmptyStateSubtitle} style={{fontSize: "20px"}}><strong>Authentication configuration takes a few minutes to apply. </strong></h2>
                    <h2 className={styles.chatEmptyStateSubtitle} style={{fontSize: "20px"}}><strong>If you deployed in the last 10 minutes, please wait and reload the page after 10 minutes.</strong></h2>
                </Stack>
            ) : (
                <Stack horizontal className={styles.chatRoot} id="main">
                        <div className={styles.chatContainer} data-theme={isDarkMode? "dark" :""} >
                    <div className={styles.headerContainer}>                        
                    <header className={styles.header} role={"banner"}>
                                              
                        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" className={styles.headerInterior}>
                        <MediaQuery query="(min-width: 601px)">
                            <Stack horizontal verticalAlign="center"  horizontalAlign="start">
                            <a aria-label="Back" href={branding?.header_left_link} target="_self" role="button" title="Back"><ChevronLeft12Filled style={{ color: "#FFFFFF"}} /></a>
                                <img
                                    src={branding?.header_left_image}
                                    className={styles.headerIconLeft}
                                    aria-label="Back"
                                    alt="Back"
                                />
                            </Stack>
                            </MediaQuery>
                          
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 4 }} horizontalAlign="center">
                                    {(window === window.parent) &&
                                    <MediaQuery query="(max-width: 600px)">
                                            <a aria-label="Back" href={branding?.header_left_link} target="_self" role="button" title="Back"><ChevronLeft12Filled style={{
                                                color: "#FFFFFF" ,display: "flex", position: "fixed",top: "36px"}} /></a>
                                    </MediaQuery>}
                                    <button 
                                        className={headerDDClass}
                                        onClick={(): void => toggleDropDown()}
                                        onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
                                            dismissHandler(e)
                                        }
                                    >
                                    <h1 className={headerClass}>
                                        {/* {branding?.header_title} */}
                                        {selectPgm ? selectPgm : pList[0].split("<>")[0]} 
                                        {branding?.show_heater_title_beta == "Y"?<sup style={{fontSize: "12px", lineHeight:"12px"}}> Beta</sup>:""}
                                        <ChevronDown16Filled style={{ marginLeft:"10px" ,color: "#FFFFFF" }} />
                                    </h1>
                                  
                                    {/* <div>{selectPgm ? "Select: " + selectPgm : "Select ..." + pList[0]} </div> */}
                                    {showDropDown && isMsgRenderComplete && (
                                    <DropDown
                                        
                                        ddInput={pgmInput()}
                                        showDropDown={false}
                                        toggleDropDown={(): void => toggleDropDown()}
                                        ddSelection={pgmSelection}
                                                    />)}
                                            </button>
                        
                            </Stack>
                         
                            <Stack horizontal tokens={{ childrenGap: 4 }}  horizontalAlign="end">
                                   
                                    <MediaQuery query="(max-width: 600px)"> 
                                        <img
                                            src={ branding?.header_right_small_image}
                                            className={styles.headerIconRightSmall}
                                            aria-hidden="true"
                                            alt="Brand Name"
                                        /> 
                                    </MediaQuery>
                                 
                                    <MediaQuery query="(min-width: 601px)"> 
                                    <img
                                        src={branding?.header_right_image}
                                        className={styles.headerIconRight}
                                        aria-hidden="true"
                                        alt="Brand Name"
                                    /> 
                                    {branding?.show_site_translate == "Y" &&
                                        <Stack horizontal verticalAlign="center" horizontalAlign="end" style={{ width: "100%" }}>
                                            <div style={{ width: "100%" }}>
                                                <div className={styles.languageBar}>
                                                                <div style={{ paddingRight: "5px", verticalAlign: "center" }} onClick={() => handleTranslate()}>
                                                        <img src={WorldIcon} alt="Language Settings" className={styles.languageImg} 
                                                           aria-label="Language Settings" role="button" tabIndex={0} title="Language Settings"></img>
                                                    </div>
                                                

                                                </div>
                                            </div>

                                        </Stack>
                                    }
                                        <Stack horizontal verticalAlign="center" horizontalAlign="end" style={{ width: "100%" }}>
                                            <div style={{ width: "100%" }}>
                                                <div className={styles.languageBar}>
                                                    <div style={{ paddingRight: "5px", verticalAlign: "center" }} onClick={() => setIsDarkMode(!isDarkMode)}>
                                                        <img src={isDarkMode? darkModeIcon : lightModeIcon} alt="Light/Dark Mode" className={styles.languageImg}
                                                                    aria-label="Light/Dark Mode" role="button" tabIndex={0} title="Light/Dark Mode"></img>
                                                    </div>


                                                </div>
                                            </div>

                                        </Stack>
                                    </MediaQuery>
                                    <MediaQuery query="(max-width: 600px)"> 
                                        <Popover data-theme={isDarkMode? "dark" :""}    
                                            isOpen={isPopoverOpen}
                                            positions={['bottom']} // preferred positions by priority
                                            content={<div style={{ padding:'10px'}} >
                                               <div>
                                                    <div style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => handleTranslateOpen()}>Select Language</div>
                                                    <div style={{ cursor: 'pointer' }} onClick={() => handleChangeModeOpen()}>Change Display Mode</div>
                                               </div></div>}
                                            onClickOutside={() => setIsPopoverOpen(false)}
                                            ref={clickMeButtonRef} // if you'd like a ref to your popover's child, you can grab one here
                                            transform={{ top: 40, left: 5}}
                                            transformMode='relative'
                                            align='end'
                                            nudgedLeft={20}
                                                    containerStyle={{
                                                        padding: '10px', fontFamily: 'Arial', fontSize: '14px', fontStyle: 'normal', fontWeight: 'normal', zIndex: '99', top: '-2px',
                                                        lineHeight: '18px', letterSpacing: '0.1px', border: 'none', background: isDarkMode ? '#1C1B2C' : 'white', color: isDarkMode ? '#FFFFFF' : '#1C1B2C',
                                            boxShadow: '0px 4px 4px 0px rgba(50, 152, 135, 0.15), 0px 1px 1px 0px rgba(12, 19, 41, 0.35)'}}
                                            > 
                                            
                                                <button aria-label="Additional Chat Options" title="Additional Chat Options"   style={{ cursor:'pointer', background: "transparent", border: "none", height: "40px"}}   onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                                <img
                                                    src={OverflowDrawer}
                                                            className={styles.overflowDrawerWhite}
                                                    aria-hidden="true"
                                                    alt="Additional Chat Options"
                                                /></button>
                                        </Popover>
                                             
                                       
                                    </MediaQuery>

                            </Stack>
                        </Stack>     
                       
                          
                       
                            
                    </header>
                                
                    </div> 
                            {!disabledButton() && (
                                <div className={
                                    window.innerWidth >= 992 ? 
                                        isCitationPanelOpen ? styles.headerTagBgBoth : styles.headerTagBg 
                                        : isCitationPanelOpen ? styles.displayNone :  styles.headerTagBg}>   
                            <Stack horizontal horizontalAlign="space-between" verticalAlign="center" style={{width:"100%"}}>
                                    <Stack horizontal horizontalAlign="start" verticalAlign="center" className={styles.headerTagEmpty}> </Stack>
                                    <Stack horizontal horizontalAlign="end" verticalAlign="center" className={styles.feedbackStack}>
                                            <div aria-label="Clear" title="Clear" className={styles.headerTagButton} 
                                                onClick={e => newChatMisc()}>
                                            <img
                                                src={restorePageIcon}
                                                className={styles.headerTagButtonImg}
                                                aria-hidden="false"
                                                alt="Clear"
                                                />{window.innerWidth >= 600 ? "CLEAR":""}</div>
                                            <div aria-label="Download" title="Download" className={styles.headerTagButton} 
                                            onClick={e => exportChatPDF()}>
                                            <img
                                                src={downloadIcon}
                                                className={styles.headerTagButtonImg}
                                                aria-hidden="false"
                                                alt="Download"
                                                />{window.innerWidth >= 600 ? "DOWNLOAD":""}</div>
                                            <div aria-label="Feedback" title="Feedback" className={styles.headerTagButton} onClick={e => handleFeedbackModalClick()}>
                                            <img
                                                src={feedbackIcon}
                                                className={styles.headerTagButtonImg}
                                                aria-hidden="false"
                                                alt="Feedback"
                                                />{window.innerWidth >=600? "FEEDBACK" :""}</div>
                                    </Stack>  
                            </Stack>
                            </div>)}
                    <MediaQuery query="(min-width: 992px)"> 
                    {isFAQPaneOpen && (
                        <Stack horizontal horizontalAlign="start" style= {{maxWidth:'25%',  marginRight: '25px', position: 'fixed',zIndex:'98',left: '0'}}>
                                        <div className={isDarkMode ? styles.spContainerDeskTopDM :styles.spContainer}>
                                <Stack horizontal verticalAlign="center" horizontalAlign="start" className={styles.spTitleContainer}>   
                                    <StackItem className={styles.spTitle}>Frequently Asked Questions</StackItem>
                                    <StackItem onClick={() => setIsFAQPaneOpen(false)} className={closeX} role="button" tabIndex={0}> {'X'}</StackItem>
                                </Stack>
                                { fList.map((item:faq) => (
                                 <> 
                                   <Stack role="button" tabIndex={0}   horizontal verticalAlign="center" horizontalAlign="start" className={isMsgRenderComplete?styles.spRegContainer : styles.spRegContainerInactive}
                                            onClick={() => isMsgRenderComplete ? makeApiRequestAuth(item.question, "Y", item.answer, item.requiresAuth, item.authType, item.authFields) : void(0)}>
                                                <StackItem className={styles.spReg}>{item.question}</StackItem>
                                                <StackItem className={styles.spAction}> {'>'}</StackItem>
                                            </Stack>  
                                          </>
                                      ))}
 
                                </div>             
                            
                                    
                        </Stack>)}
                    </MediaQuery>
                     
                        {/* {!messages || messages.length < 1 ? 
                            ( */}
                                {/* <Stack className={appStateContext?.state.isFeedbackPanelOpen   ? styles.displayNone :styles.chatEmptyState}>
                                </Stack> */}
                            {/* ) : 
                            ( */}
                            <div data-theme={isDarkMode? "dark" :""}  className={isFAQPaneOpen ? isCitationPanelOpen ? styles.chatMainNudgeBoth : styles.chatMainNudge : isCitationPanelOpen ? styles.chatMainNudgeRight : styles.chatMain}>
                            <Stack  horizontal  >
                          
                                  <Stack   style= {{width: '100%'}}>
                                        
                                        <div ref={chatStreamContainerRef} data-theme={isDarkMode? "dark" :""}
                                  className={isFAQPaneOpen? styles.chatMessageStreamNudge : styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px"}} role="log">
                                            
                                            <Stack className={isDarkMode ? styles.answerContainerDM : styles.answerContainer} tabIndex={0}>
                                                <Stack horizontal horizontalAlign="start" className={styles.ansCont}>
                                              <Stack horizontal horizontalAlign="start">
                                                  <img
                                                      src={isDarkMode ? branding?.msg_icon_darkmode : branding?.msg_icon}
                                                      className={styles.msgIcon}
                                                      aria-hidden="true"
                                                      alt="Message Icon"
                                                  />
                                              </Stack>
                                                    <Stack horizontal grow >
                                                  <Stack.Item grow>
                                                        <Stack horizontal horizontalAlign="space-between">  
                                                        <Stack horizontalAlign="start" className={styles.msgTimeA}>{isNewConv? dt1 : dt2} </Stack> 
                                                                <Stack horizontalAlign="end" style={{ cursor: "pointer" }} aria-label="Copy" title="Copy"
                                                        onClick={() => copyToClipboard(branding?.brand_welcome_message_line1 ? branding?.brand_welcome_message_line1 : " ")}> 
                                                                    {/* <img src={CopyIcon} aria-label="Copy" alt="Copy" title="Copy" role="button" tabIndex={0} /> */}
                                                                    <CopyRegular role="button" tabIndex={0} className={styles.errorIcon} style={{ color: isDarkMode ? 'white' : 'black', height: '22px', width: '22px', outline: 'none' }} aria-label="Copy" title="Copy" />
                                                        </Stack>
                                                        </Stack>
                                                      <ReactMarkdown
                                                          linkTarget="_blank"
                                                          remarkPlugins={[remarkGfm, supersub]} 
                                                          children={branding?.brand_welcome_message_line1? branding?.brand_welcome_message_line1 : " "}
                                                          className={styles.answerText}
                                                      />
                                                  </Stack.Item>
                                              </Stack>
                                          </Stack>
                                  </Stack>
                                 <div style={{height: '10px'}}></div>
                                            <Stack className={isDarkMode ? styles.answerContainerDM : styles.answerContainer} tabIndex={0}>
                                                <Stack horizontal horizontalAlign="start" className={styles.ansCont}>
                                          <Stack horizontal horizontalAlign="start">
                                              <img
                                                  src={isDarkMode ? branding?.msg_icon_darkmode : branding?.msg_icon}
                                                  className={styles.msgIcon}
                                                  aria-hidden="true"
                                                  alt="Message Icon"
                                              />
                                          </Stack>
                                          <Stack horizontal grow >
                                              <Stack.Item grow>
                                                <Stack horizontal horizontalAlign="space-between"> 
                                                    <Stack horizontalAlign="start" className={styles.msgTimeA}>{isNewConv? dt1 : dt2}</Stack> 
                                                                <Stack horizontalAlign="end" style={{ cursor: "pointer" }} aria-label="Copy" title="Copy"
                                                    onClick={() => copyToClipboard(branding?.brand_welcome_message_line2 ? branding?.brand_welcome_message_line2 : " ")}> 
                                                                    {/* <img src={CopyIcon} aria-label="Copy" alt="Copy" title="Copy" role="button"  tabIndex={0}/> */}
                                                                    <CopyRegular role="button" tabIndex={0} className={styles.errorIcon} style={{ color: isDarkMode ? 'white' : 'black', height: '22px', width: '22px', outline: 'none' }} aria-label="Copy" title="Copy"  />
                                                                    </Stack>
                                                </Stack>
                                                  <ReactMarkdown
                                                      linkTarget="_blank"
                                                      remarkPlugins={[remarkGfm, supersub]}
                                                      children={branding?.brand_welcome_message_line2? branding?.brand_welcome_message_line2 : " "}
                                                      className={styles.answerText}
                                                  />
                                              </Stack.Item>
                                          </Stack>
                                      </Stack>
                                  </Stack>
                              
                                    {/* < div ref={pdfRef}> */}
                                    <div>
                                      {messages.map((answer, index) => (
                                          <>
                                              {answer.role === "user" ? (
                                                  <div className={styles.chatMessageUser} tabIndex={0}>
                                                     
                                                      <div className={styles.chatMessageUserMessage}>
                                                          <div> 
                                                              <Stack horizontalAlign="start" className={styles.msgTime}>{new Date(answer.date).toLocaleTimeString([], {hour: 'numeric', minute: '2-digit', hour12: true})}</Stack>
                                                              <Stack horizontalAlign="start">{answer.content}</Stack>
                                                          </div>
                                                      </div>    
                                                  </div>
                                                  
                                              ) : (
                                                  answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                                                     
                                                      <Answer
                                                          answer={{
                                                              answer: answer.content,
                                                              followup_q: answer.context,
                                                              citations: appStateContext?.state.frontendSettings?.branding?.citation_disabled === "true"?[]:parseCitationFromMessage(messages[index - 1]),
                                                              message_id: answer.id,
                                                              feedbackOnMsg: answer.feedbackOnMsg, 
                                                              msgStatus: processMessages,
                                                              date: answer.date,
                                                              plotly_data: parsePlotFromMessage(messages[index - 1]),
                                                              exec_results: execResults,
                                                              isCopilotResponse: answer.copilot_convId? "Y": "N",
                                                              
                                                          }}
                                                          onCitationClicked={c => onShowCitation(c)}
                                                          isLoading={isLoading}
                                                          isDone={isDone}
                                                          msgIndex={index}
                                                          isMsgRenderComplete={isMsgRenderComplete}
                                                          setIsMsgRenderComplete={setIsMsgRenderComplete}
                                                          darkmode={isDarkMode}
                                                          pgm={selectPgmCode}
                                                          onExecResultClicked={() => onShowExecResult()} 
                                                          speechUrl={speechUrls[index]}
                                                          isMsgPlaying={isMsgPlaying}
                                                          setIsMsgPlaying={setIsMsgPlaying}
                                                            //   msgCurrentTime={msgCurrentTime}
                                                            //   setMsgCurrentTime={setMsgCurrentTime}
                                                            //   isMsgPlayPaused={isMsgPlayPaused}./start

                                                            //   setIsMsgPlayPaused={setIsMsgPlayPaused}
                                                            isNewConv={isNewConv}
                                                            stopMsgPlaying = {stopMsgPlaying}
                                                              onFollowupQuestionClicked={q => makeApiRequestAfterClear(q, "N", authType !== ""? "Y":"N")}
                                                            showFollowupQuestions={showFollowupQuestions}
                                                        
                                                      />
                                                  </div> : answer.role === ERROR ? 
                                                      <div className={styles.chatMessageError}>
                                                      <Stack horizontal className={styles.chatMessageErrorTitle}>
                                                          <ErrorCircleRegular className={styles.errorIcon} style={{color: "#FFFFFF", height:'22px', width:'22px'}} />
                                                          <span>Error</span>
                                                    {/* <Stack horizontalAlign="end" verticalAlign="center"
                                                        onClick={() => handleErrorDialogClose()} className={styles.closeModalErr}>{'OK'}</Stack>*/}
                                                      </Stack> 
                                                                  <span className={styles.chatMessageErrorContent}><div style={{paddingLeft:'33px'}}>{answer.content}</div></span>
                                                  </div> : null
                                              )}
                                          </>
                                      ))}
                                      </div>
                                        {showLoadingMessage  && ( 
                                          <>
                                              <div className= {styles.chatMessageGpt}>
                                                  <Answer
                                                      answer={{
                                                          answer: "Generating answer...",
                                                          followup_q: assistantContextO,
                                                          citations: [] ,
                                                          msgStatus: processMessages,
                                                          date: new Date().toISOString(),
                                                          plotly_data: null,
                                                          isCopilotResponse:  "N",
                                                      }}
                                                      onCitationClicked={() => null}
                                                      isLoading={isLoading}
                                                      isDone={isDone}
                                                      isMsgRenderComplete={isMsgRenderComplete}
                                                      setIsMsgRenderComplete={setIsMsgRenderComplete}
                                                      darkmode={isDarkMode}
                                                      pgm={selectPgmCode}
                                                      onExecResultClicked={() => onShowExecResult()} 
                                                      speechUrl={null}
                                                      isMsgPlaying={isMsgPlaying}
                                                      setIsMsgPlaying={setIsMsgPlaying}
                                                            // msgCurrentTime={msgCurrentTime}
                                                            // setMsgCurrentTime={setMsgCurrentTime}
                                                            // isMsgPlayPaused={isMsgPlayPaused}
                                                            // setIsMsgPlayPaused={setIsMsgPlayPaused}
                                                            isNewConv={isNewConv}
                                                            stopMsgPlaying={stopMsgPlaying}
                                                  />
                                              </div>
                                          </>
                                       )} 
                                            {copyStatus && <p style={{color: isDarkMode? "#FFFFFF" : "#000000"}}>Text copied to clipboard!</p>}
                                      {messages && messages.length > 0 && isMsgRenderComplete &&<div className={styles.aiGenContent}>AI generated content</div>}
                                     
                                      <div  className={styles.chatMessageStreamEndNew} />
                                      <div ref={chatMessageStreamEnd}  className={styles.chatMessageStreamEnd} />
                                      {/* <div ref={chatMessageStreamEnd} /> */}
                                    
                                      </div>
                                        
                                      </Stack>
                           
                                              {/* Citation Panel */}
                                            
                                    {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && window.innerWidth < 992 && ( 
                                               <SlidingPane className="sliding-pane" isOpen={isCitationPanelOpen} from= {"right"}
                                            width={window.innerWidth <= 600 ? "100%" : "50%"} hideHeader onRequestClose={() =>   { setIsCitationPanelOpen(false);}}  >
                                            <Stack horizontal horizontalAlign="start" style={{ maxWidth: '100%' }}>
                                                <div className={styles.spContainerCitation}>
                                                    <Stack horizontal verticalAlign="center" horizontalAlign="start" className={styles.spTitleContainerCitation}>
                                                        <StackItem className={styles.spTitleCitation}>{activeCitation.title ? activeCitation.title : "Citation"}</StackItem>
                                                        <StackItem onClick={() => setIsCitationPanelOpen(false)} className={closeXCitation} role="button" tabIndex={0}> {'X'}</StackItem>
                                                    </Stack>

                                                    <div tabIndex={0}>
                                                        <ReactMarkdown
                                                            linkTarget="_blank"
                                                            className={styles.spTextCitation}
                                                            children={DOMPurify.sanitize(activeCitation.content.replace(activeCitation.title ? activeCitation.title : "", ""), { ALLOWED_TAGS: XSSAllowTags })}
                                                            remarkPlugins={[remarkGfm]}
                                                            rehypePlugins={[rehypeRaw]}
                                                        />
                                                    </div>

                                                </div>


                                            </Stack>      
                                                </SlidingPane>  
                                                 
                                             
                                        )}
                                    </Stack>
                          
                            </div>
                              
                             
                            {/* )
                        } */}
                            <MediaQuery query="(min-width: 992px)">
                                {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && window.innerWidth >= 992 && (
                                    <Stack horizontal horizontalAlign="start" style={{ maxWidth: '25%', position: 'fixed', right: '25%' }}>
                                        <div className={styles.spContainerCitation}>
                                            <Stack horizontal verticalAlign="center" horizontalAlign="start" className={styles.spTitleContainerCitation}>
                                                <StackItem className={styles.spTitleCitation}>{activeCitation.title ? activeCitation.title : "Citation"}</StackItem>
                                                <StackItem onClick={() => setIsCitationPanelOpen(false)} className={closeXCitation} role="button" tabIndex={0}> {'X'}</StackItem>
                                            </Stack>
                                          
                                            <div tabIndex={0}>
                                                <ReactMarkdown
                                                    linkTarget="_blank"
                                                    className={styles.spTextCitation}
                                                    children={DOMPurify.sanitize(activeCitation.content.replace(activeCitation.title ? activeCitation.title : "", ""), { ALLOWED_TAGS: XSSAllowTags })}
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw]}
                                                />
                                            </div>

                                        </div>


                                    </Stack>)}
                            </MediaQuery>    
                    {/* <div className={appStateContext?.state.isFeedbackPanelOpen || hideFeedbackButton() ? styles.displayNone : styles.feedback}>
                          
                          <Stack  horizontalAlign="end" className={styles.feedbackStack}>  
                              <CommandBarButton
                                  role="button"
                                  className={styles.feedbackButton}
                                  onClick={handleFeedbackModalClick}
                                  text="FEEDBACK"
                                  aria-label="FEEDBACK"
                                  title="FEEDBACK"
                          /></Stack>                   
                   </div>    */}
                 
                        <Stack horizontal className=
                        {appStateContext?.state.isFeedbackPanelOpen || 
                                (((isFAQPaneOpen && window.innerWidth < 992)  || isCautionModalPanelOpen || isUserTermsModalPanelOpen || isPrivacyPolicyModalPanelOpen)
                                ) || (isCitationPanelOpen && window.innerWidth < 992) ? styles.displayNone : 
                                isFAQPaneOpen ? isCitationPanelOpen ? styles.chatInputNudgeBoth : styles.chatInputNudge : isCitationPanelOpen ? styles.chatInputNudgeRight : styles.chatInput}>
                             <Stack>
                                    <Dialog data-theme={isDarkMode ? "dark" : ""}    
                                    hidden={hideErrorDialog}
                                    onDismiss={handleErrorDialogClose}
                                    dialogContentProps={errorDialogContentProps}
                                    modalProps={modalProps}
                                    styles={{main: [{ selectors: { ['@media (min-width: 280px)']: {
                                        maxWidth: '90%', background: isDarkMode ? "#1C1B2C" : "#FFFFFF", boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",  borderRadius: "8px",
                                        color: isDarkMode ? "#FFFFFF" : "#000000", maxHeight: '90%',minHeight: '100px',borderColor: '#155146',  borderStyle: 'solid', borderWidth: '1px',margin: '15px'}}}]}}/>
                            </Stack>
                                <Stack horizontal horizontal-horizontalAlign="stretch" 
                                    className={isFAQPaneOpen ? isCitationPanelOpen ? styles.questionInputDivNudgeBoth : styles.questionInputDivNudge : isCitationPanelOpen ? styles.questionInputDivNudgeRight: styles.questionInputDiv}  >
                            <QuestionInput
                                clearOnSend
                                placeholder={Math.max(window.screen.width, window.innerWidth) <630? branding?.placeholder_abbrev_text ? branding?.placeholder_abbrev_text : "": branding?.placeholder_long_text ? branding?.placeholder_long_text : ""}
                                disabled={!isMsgRenderComplete}
                                onSend={(question, id) => {
                                    appStateContext?.state.isCosmosDBAvailable?.cosmosDB ? makeApiRequest(question,"N", "N",id) : makeApiRequestWithoutCosmosDB(question, id)
                                }}
                                conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                                speechEnabled={branding?.speech_enabled}
                                stopMsgPlaying={stopMsgPlaying}
                                setStopMsgPlaying={setStopMsgPlaying}
                            />
                       
                        {/* <MediaQuery query="(min-width: 1200px)">     */}
                        <Stack horizontal   horizontalAlign="baseline"  className={appStateContext?.state.isFeedbackPanelOpen  ? styles.displayNone :styles.cautionContainer}>
                        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" className={styles.cautionInterior}>
                         
                                <Stack horizontal verticalAlign="center"  horizontalAlign="start">
                                    <CommandBarButton
                                                role="button"
                                                className={styles.faqImgButton}
                                                onClick={handleFAQClick}
                                                text="FAQs"
                                                aria-label="FAQs"
                                                title="FAQs"
                                                styles={{
                                                    textContainer: {
                                                      overflow: 'hidden',
                                                    },
                                                    label: {
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }
                                                  }}
                                        />
                                        
                                                <Stack horizontal horizontalAlign="start" verticalAlign="center" className={copyRight} title={branding?.show_copyright === 'Y' ? branding?.copyright_text : ''}> 
                                                    {branding?.show_copyright === "Y" && (
                                                        <>
                                                            <MediaQuery query="(max-width: 600px)">
                                                                {"©"}
                                                            </MediaQuery>
                                                            <MediaQuery query="(min-width: 601px)">
                                                                {branding?.copyright_text} 
                                                            </MediaQuery>
                                                        </>
                                                    )}
                                                </Stack>
                                  
                                </Stack>
                            
                                
                                         
                                <Stack horizontal   horizontalAlign="end"  style= {{color: '#FFFFFF'}} >
                                    <Stack horizontal verticalAlign="center" horizontalAlign="end" className={styles.userTerms}>
                                        {branding?.show_user_terms == "Y" &&
                                            <CommandBarButton
                                                role="button"
                                                className={styles.userTermsButton}
                                                onClick={handleUserTermsModalClick}
                                                text="Terms"
                                                aria-label="Terms"
                                                title="Terms"
                                                styles={{
                                                    textContainer: {
                                                      overflow: 'hidden',
                                                    },
                                                    label: {
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }
                                                  }}
                                            />
                                        }
                                    </Stack>   
                                    <Stack horizontal verticalAlign="center" className={styles.privacyPolicy} style= {{color: '#FFFFFF'}}  > 
                                        {branding?.show_privacy_policy == "Y"  &&
                                            <CommandBarButton
                                            role="button"
                                            className={styles.privacyPolicyButton}
                                            onClick={handlePrivacyPolicyModalClick}
                                            text="Privacy"
                                            aria-label="Privacy"
                                            title="Privacy"
                                            styles={{
                                                textContainer: {
                                                  overflow: 'hidden',
                                                },
                                                label: {
                                                  overflow: 'hidden',
                                                  whiteSpace: 'nowrap',
                                                  textOverflow: 'ellipsis',
                                                }
                                              }}
                                        />}
                                    </Stack>
                                    <CommandBarButton
                                                role="button"
                                                className={styles.cautionImgButton}
                                                onClick={handleCautionModalClick}
                                                text="AI Cautions"
                                                aria-label="AI Cautions"
                                                title="AI Cautions"
                                                styles={{
                                                    textContainer: {
                                                      overflow: 'hidden',
                                                    },
                                                    label: {
                                                      overflow: 'hidden',
                                                      whiteSpace: 'nowrap',
                                                      textOverflow: 'ellipsis',
                                                    }
                                                  }}
                                        />
                                
                                </Stack>
                            </Stack>    
                            </Stack>
                            {/* </MediaQuery> */}
                            {/* <MediaQuery query="(max-width: 1199px)">
                            {(branding?.show_user_terms == "Y" ||  branding?.show_privacy_policy == "Y") && (
                                <Stack horizontal   horizontalAlign="baseline"  className={appStateContext?.state.isFeedbackPanelOpen  ? styles.displayNone :styles.cautionContainer}>    
                                    <Stack  horizontal  verticalAlign="center"  horizontalAlign="start"> 
                                    {branding?.show_user_terms == "Y" &&
                                        
                                    <CommandBarButton
                                    role="button"
                                    className={styles.userTermsButton}
                                    onClick={handleUserTermsModalClick}
                                    text="Terms"
                                    aria-label="Terms"
                                    title="Terms"
                                    />  }
                                    </Stack >
                                    <Stack horizontal   horizontalAlign="end"  style= {{color: '#FFFFFF'}} > 
                                        {branding?.show_privacy_policy == "Y"  && 
                                          
                                        <CommandBarButton
                                        role="button"
                                        className={styles.privacyPolicyButton }
                                        onClick={handlePrivacyPolicyModalClick}
                                        text="Privacy"
                                        aria-label="Privacy"
                                        title="Privacy"
                                    />}
                                    </Stack>
                                   
                                </Stack>)}
                                <Stack horizontal   horizontalAlign="baseline"  className={appStateContext?.state.isFeedbackPanelOpen  ? styles.displayNone :styles.cautionContainer}>
                                  <Stack  horizontal  verticalAlign="center"  horizontalAlign="start"> 
                                    <CommandBarButton
                                                role="button"
                                                className={styles.faqImgButton}
                                                onClick={handleFAQClick}
                                                text="FAQs"
                                                aria-label="FAQs"
                                                title="FAQs"
                                        />
                                 </Stack>
                            
                            
                                <Stack horizontal   horizontalAlign="end"  style= {{color: '#FFFFFF'}} > 
                                 
                                    <CommandBarButton
                                                role="button"
                                                className={styles.cautionImgButton}
                                                onClick={handleCautionModalClick}
                                                text="AI Cautions"
                                                aria-label="AI Cautions"
                                                title="AI Cautions"
                                        />
                                
                                </Stack>
                                
                                </Stack>   
                                {branding?.show_copyright== "Y" && (
                                <Stack horizontal   horizontalAlign="baseline"  className={appStateContext?.state.isFeedbackPanelOpen  ? styles.displayNone :styles.cautionContainer}> 
                                    <Stack  horizontalAlign="center" verticalAlign="center" className={copyRight} > 
                                    {branding?.show_copyright== "Y"?  branding?.copyright_text:""}
                                    </Stack> 
                                </Stack>
                                )}
                            </MediaQuery>
                       */}
                                <SlidingPane
                                    className={styles.spCustom}
                                    isOpen={appStateContext?.state.isFeedbackPanelOpen}
                                    from={"bottom"}
                                        width={window.innerWidth >= 992 ? "60%" : "100%"}
                                    hideHeader
                                        onRequestClose={() => { handleFeedbackModalClick }}
                                >
                                        <div className={styles.modalMain} data-theme={isDarkMode? "dark" :""}>
                                            <Stack className={styles.modalContainer} data-theme={isDarkMode? "dark" :""}>
                                                <Stack horizontal verticalAlign="center" className={styles.modalHRNew} data-theme={isDarkMode? "dark" :""}>
                                                <Stack horizontalAlign="start" verticalAlign="center">
                                                <img src={isDarkMode ? branding?.dialog_logo_darkmode : branding?.dialog_logo} aria-hidden="true" alt="Brand Logo" className={styles.modalIcon} /></Stack>
                                                <Stack horizontalAlign="space-between" verticalAlign="center" className={styles.spSubtitleContainer}> <b className={styles.modalEmptyStateSubtitle}>Share Your Feedback</b></Stack>
                                                <Stack horizontalAlign="end" verticalAlign="center" role="button" tabIndex={0}
                                                        onClick={handleFeedbackModalClick} className={closeXOther}>{'X'}</Stack>

                                            </Stack>
 
                                            <Feedback
                                                clearOnSend
                                                placeholder="Type here..."
                                                disabled={!isMsgRenderComplete}
                                                darkmode={isDarkMode}
                                                onSend={(rating, feedback, id, messageid, messagefb) => {
                                                    sendFeedback(rating, feedback, id, messageid, messagefb)
                                                }}
                                                conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}

                                            />
                                                <input id="text1" type="text" value={text1} contentEditable={false} className={styles.modalInt} data-theme={isDarkMode? "dark" :""} />
                                        </Stack>
                                </div>
                                </SlidingPane>
                                <SlidingPane 
                                    className={styles.spCustom}
                                    isOpen={isCautionModalPanelOpen}
                                    from={"bottom"}
                                        width={window.innerWidth >= 992 ? "60%" : "100%"}
                                    hideHeader
                                    onRequestClose={() =>   { handleCautionModalPanelDismiss}}
                                >
                                        <div className={styles.modalMain} data-theme={isDarkMode? "dark" :""}>
                                            <Stack className={styles.modalContainer} data-theme={isDarkMode? "dark" :""}>
                                                <Stack horizontal verticalAlign="center" className={styles.modalHRNew} data-theme={isDarkMode? "dark" :""}>
                                                <Stack horizontalAlign="start" verticalAlign="center">    
                                                        <img src={isDarkMode ? branding?.dialog_logo_darkmode : branding?.dialog_logo} aria-hidden="true" alt="Brand Logo" className={styles.modalIcon}/></Stack>
                                                <Stack horizontalAlign="space-between" verticalAlign="center" className={styles.spSubtitleContainer}> <b className={styles.modalEmptyStateSubtitle}>AI Cautions</b></Stack>
                                                <Stack horizontalAlign="end" verticalAlign="center" role="button" tabIndex={0}
                                                onClick={() => setIsCautionModalPanelOpen(false)} className={closeXOther}>{'X'}</Stack>
                                              
                                            </Stack>
                                          
                                            <ReactMarkdown linkTarget="_blank"     remarkPlugins={[remarkGfm, supersub]}
                                                          children=        {branding?.caution_text? branding?.caution_text:""}  
                                                          className={styles.modalContainerInt}  />           
                                      
                                                <h5 className={styles.modalDt}>{branding?.updated_on}</h5> 
                                                <input id="text1" type="text" value={text1} contentEditable={false} className={styles.modalInt} />
                                        </Stack>
                                    </div>
                                </SlidingPane>
                                    <SlidingPane className={styles.spCustom} isOpen={isPrivacyPolicyModalPanelOpen} from={"bottom"} width={window.innerWidth >= 992 ? "60%" : "100%"}
                                    hideHeader onRequestClose={() =>   { handlePrivacyPolicyModalPanelDismiss}}   >
                                        <div className={styles.modalMain} data-theme={isDarkMode? "dark" :""}>
                                            <Stack className={styles.modalContainer} data-theme={isDarkMode? "dark" :""}>
                                                <Stack horizontal verticalAlign="center" className={styles.modalHRNew} data-theme={isDarkMode? "dark" :""}>
                                                <Stack horizontalAlign="start" verticalAlign="center">    
                                                        <img src={isDarkMode ? branding?.dialog_logo_darkmode : branding?.dialog_logo} aria-hidden="true" alt="Brand Logo" className={styles.modalIcon} />
                                                </Stack>
                                                <Stack horizontalAlign="space-between" verticalAlign="center" className={styles.spSubtitleContainer}> <b className={styles.modalEmptyStateSubtitle}>Privacy Policy</b></Stack>
                                                <Stack horizontalAlign="end" verticalAlign="center" role="button" tabIndex={0}
                                                onClick={() => setIsPrivacyPolicyModalPanelOpen(false)} className={closeXOther}>{'X'}</Stack>
                                              
                                            </Stack>
                                          
                                            <br/> 
                                             <ReactMarkdown linkTarget="_blank"     remarkPlugins={[remarkGfm, supersub]}
                                                          children=  {branding?.privacy_policy_text ? branding?.privacy_policy_text:""}
                                                          className={styles.modalContainerInt}  />
                                                <input id="text2" type="text" value={text2} contentEditable={false} className={styles.modalInt} data-theme={isDarkMode? "dark" :""} />              
                                        </Stack>
                                    </div>
                                </SlidingPane>
                                <SlidingPane     className={styles.spCustom} isOpen={isUserTermsModalPanelOpen}
                                        from={"bottom"} width={window.innerWidth >=992 ? "60%": "100%" }   hideHeader  onRequestClose={() =>   { handleUserTermsModalPanelDismiss}}  >
                                        <div className={styles.modalMain} data-theme={isDarkMode? "dark" :""}>
                                            <Stack className={styles.modalContainer} data-theme={isDarkMode? "dark" :""}>
                                                <Stack horizontal verticalAlign="center" className={styles.modalHRNew} data-theme={isDarkMode? "dark" :""}>
                                                <Stack horizontalAlign="start" verticalAlign="center">    
                                                        <img src={isDarkMode ? branding?.dialog_logo_darkmode : branding?.dialog_logo} aria-hidden="true" alt="Brand Logo" className={styles.modalIcon}/></Stack>
                                                <Stack horizontalAlign="space-between" verticalAlign="center" className={styles.spSubtitleContainer}> <b className={styles.modalEmptyStateSubtitle}>Terms of Use</b></Stack>
                                                <Stack horizontalAlign="end" verticalAlign="center" role="button" tabIndex={0}
                                                onClick={() => setIsUserTermsModalPanelOpen(false)} className={closeXOther}>{'X'}</Stack>
                                              
                                            </Stack>
                                          
                                            <br/> 
                                             <ReactMarkdown linkTarget="_blank" remarkPlugins={[remarkGfm, supersub]}
                                                          children= {branding?.user_terms_text?branding?.user_terms_text:""}
                                                          className={styles.modalContainerInt}  />
                                                <input id="text3" type="text" value={text3} contentEditable={false} className={styles.modalInt} data-theme={isDarkMode? "dark" :""} />             
                                        </Stack>
                                    </div>
                                </SlidingPane>
                            
                           
                            <MediaQuery query="(max-width: 991px)"> 
                                        <SlidingPane   isOpen={isFAQPaneOpen} from="bottom" className={styles.spCustomFAQ}
                                            width={"100%"} hideHeader onRequestClose={() =>   { setIsFAQPaneOpen(false);}}  >
                                    <div  className={styles.spContainer}>
                                        <Stack horizontal verticalAlign="center" horizontalAlign="start" className={styles.spTitleContainer}>   
                                            <StackItem className={styles.spTitle}>Frequently Asked Questions</StackItem>
                                            <StackItem onClick={() => setIsFAQPaneOpen(false)} className={closeX} role="button" tabIndex={0}> {'X'}</StackItem>
                                        </Stack>
                                        <SimpleBar style={{ height: '90%' }}>
                                        { 
                                        fList.map((item:faq) => (
                                            <> 
                                            <Stack role="button" tabIndex={0} horizontal verticalAlign="center" horizontalAlign="start" className={isMsgRenderComplete?styles.spRegContainer : styles.spRegContainerInactive}
                                                onClick={() => isMsgRenderComplete? makeApiRequestAuth(item.question, "Y", item.answer, item.requiresAuth, item.authType, item.authFields): void(0)}>
                                                <StackItem className={styles.spReg}>{item.question}</StackItem>
                                                <StackItem className={styles.spAction}> {'>'}</StackItem>
                                            </Stack>  
                                              </>
                                         ))}
                                         </SimpleBar>
                                        </div>
                            </SlidingPane>
                            </MediaQuery>
                      

                        </Stack>
                            </Stack>
                          
                            <Dialog data-theme={isDarkMode ? "dark" : ""}
                                hidden={!showPgmMessage}
                                onDismiss={handlePgmDialogClose}
                                // dialogContentProps={pgmDialogContentProps}
                                dialogContentProps={{
                                    title: "",
                                    showCloseButton: true
                                }}
                                modalProps={pgmModalProps}
                              
                                styles={{
                                   
                                    main: [{
                                        selectors: {
                                            ['@media (min-width: 280px)']: {
                                                maxWidth: '90%', background: isDarkMode ? "#1C1B2C" : "#FFFFFF", boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)", borderRadius: "8px",
                                               maxHeight: '90%', minHeight: '100px', borderColor: '#155146', borderStyle: 'solid', borderWidth: '1px', margin: '15px'
                                            }
                                        }
                                    }] 
                                }}>
                                    <Stack horizontal verticalAlign="center" horizontalAlign="baseline" data-theme={isDarkMode ? "dark" : ""}>
                                    <div className={styles.dialogTitle}data-theme={isDarkMode ? "dark" : ""}>{selectPgm + " Program "}</div>
                                    </Stack>
                                    <Stack horizontal verticalAlign="center" horizontalAlign="baseline" data-theme={isDarkMode ? "dark" : ""}>
                                    <div className={styles.dialogContent} data-theme={isDarkMode ? "dark" : ""}>{"Coming soon! Currently in development."}</div>
                                    <input id="text4" type="text" value={text4} contentEditable={false} className={styles.modalInt} data-theme={isDarkMode ? "dark" : ""} />  
                                    </Stack>
                                </Dialog>  
                            <SlidingPane
                                className={styles.spCustom}
                                isOpen={showAuthDialogMessage}
                                from={"bottom"}
                                width={window.innerWidth >= 992 ? "40%" : "100%"}
                                hideHeader
                                onRequestClose={() => { handleAuthDialogClose }}
                            >
                                <div className={styles.modalMain} data-theme={isDarkMode ? "dark" : ""}>
                                    <Stack className={styles.modalContainer} data-theme={isDarkMode ? "dark" : ""}>
                                        <Stack horizontal verticalAlign="center" className={styles.modalHRNew} data-theme={isDarkMode ? "dark" : ""}>
                                            <Stack horizontalAlign="start" verticalAlign="center">
                                                <img src={isDarkMode ? branding?.dialog_logo_darkmode : branding?.dialog_logo} aria-hidden="true" alt="Brand Logo" className={styles.modalIcon} /></Stack>
                                            <Stack horizontalAlign="space-between" verticalAlign="center" className={styles.spSubtitleContainer}> <b className={styles.modalEmptyStateSubtitle}>Information Required</b></Stack>
                                            <Stack horizontalAlign="end" verticalAlign="center" role="button" tabIndex={0}
                                                onClick={() => setShowAuthDialogMessage(false)} className={closeXOther}>{'X'}</Stack>

                                        </Stack>
                                        <Stack horizontal horizontalAlign="start" data-theme={isDarkMode ? "dark" : ""} className={styles.authInfo} >
                                            <Stack horizontalAlign="start" verticalAlign="center" className={styles.authInfoLabel}>{authFields} </Stack>
                                            <Stack horizontalAlign="space-between" verticalAlign="center" className={styles.authInfoField}>
                                                <input id="authfieldEntry" type="text" onChange={onAuthFieldValChange} placeholder="Type here"></input>
                                            </Stack>
                                        </Stack>
                                        <input id="text5" type="text" value={text1} contentEditable={false} className={styles.modalInt} />
                                        <Stack horizontal horizontalAlign="center">
                                            <CommandBarButton
                                                role="button"
                                                className={styles.closeButtonAuth}
                                                onClick={handleAuthDialogClose}
                                                text="CANCEL"
                                                aria-label="CANCEL"
                                                title="CANCEL"
                                                onKeyDown={e => e.key === "Enter" || e.key === " " ? handleAuthDialogClose() : null}
                                            />
                                            <CommandBarButton
                                                role="button"
                                                className={authFieldsVal === "" ? styles.submitButtonAuthDisabled : styles.submitButtonAuth}
                                                onClick={makeApiRequestAuthValidate}
                                                text="SUBMIT"
                                                aria-label="Submit"
                                                title="SUBMIT"
                                                onKeyDown={e => e.key === "Enter" || e.key === " " ? makeApiRequestAuthValidate() : null}
                                            />

                                        </Stack>
                                    </Stack>
                                </div>
                            </SlidingPane>
                           
                            {/* <Dialog data-theme={isDarkMode ? "dark" : ""}
                                hidden={!showTranslate}
                                onDismiss={handleTranslateDialogClose}
                                dialogContentProps={{
                                    title: "",
                                    showCloseButton: true
                                }}
                                modalProps={translateModalProps}
                                styles={{
                                    main: [{
                                        selectors: {
                                            ['@media (min-width: 280px)']: {
                                                maxWidth: '90%', background: isDarkMode ? "#1C1B2C !important" : "#FFFFFF", boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)", borderRadius: "8px",
                                                maxHeight: '90%', minHeight: '100px', borderColor: '#155146', borderStyle: 'solid', borderWidth: '1px', margin: '15px',fontFamily:'Arial !important', fontSize:"20px",
                                                color: isDarkMode ? "#FFFFFF !important" : "#000000"
                                            }
                                        }
                                    }]
                                }}>
                                <Stack horizontal verticalAlign="center" horizontalAlign="baseline" data-theme={isDarkMode ? "dark" : ""}>
                                    <div className={styles.dialogTitle} data-theme={isDarkMode ? "dark" : ""}>{"Select a language"}</div>
                                </Stack>
                                <Stack horizontal verticalAlign="center" horizontalAlign="baseline" data-theme={isDarkMode? "dark" :""}>
                                    <Stack horizontal horizontalAlign="start" data-theme={isDarkMode? "dark" :""}>
                                        <div data-theme={isDarkMode ? "dark" : ""} style={{ padding: '10px' }}>
                                            
                                       
                                    </div>
                                    </Stack>
                                </Stack>
                                <Stack horizontal verticalAlign="center" horizontalAlign="baseline" style={{ width: "100%" }} data-theme={isDarkMode? "dark" :""}>
                                    <Stack horizontal horizontalAlign="end" style={{ width: "100%" }} data-theme={isDarkMode? "dark" :""}>
                                        <CommandBarButton
                                            role="button"
                                            className={styles.closeButtonTranslate}
                                            onClick={handleTranslateDialogClose}
                                            text="CANCEL"
                                            aria-label="CANCEL"
                                            title="CANCEL"
                                            onKeyDown={e => e.key === "Enter" || e.key === " " ? handleTranslateDialogClose() : null}
                                        />
                                    </Stack>
                               </Stack>
                                </Dialog> */}
                            {branding?.show_widget === "Y" && (window  === window.parent) &&
                            <div onClick={toggleWidget} >
                                {!showWidget && 
                                <Stack horizontalAlign="end" style={{cursor:"pointer"}} onClick={toggleWidget}> 
                                            <img src={WidgetIcon} aria-label="Widget" alt="Widget" title="Widget" style={{ width: "50px", position: "fixed", right: isFAQPaneOpen ? "0px" : isCitationPanelOpen? "25%" : "12%", bottom: "150px"}} />
                                </Stack>
                               }
                              
                                    <div style={{
                                        display: "block", overflow: "visible", position: "fixed", right: "0px",  zIndex: '99', borderRadius: '10px',
                                        width: showWidget ? window.innerWidth > 600 ? "390px" : "100vw" : '0px', 
                                        height: showWidget ? window.innerWidth > 600 ? "620px" : "90vh" :'0px', 
                                        top: window.innerWidth > 600 ? "100px" : "0px",
                                    }}>
                                        <a title="Minimize Widget" aria-label='Minimize Widget' role="button" onClick={hideWidget} style={{position: "fixed", color: "white", cursor: "pointer", fontSize: "30px",
                                            visibility: showWidget ? "visible" : 'hidden', 
                                            right: branding?.widget_url.indexOf('copilotstudio') >= 0 ? "35px" :  "62px", 
                                            top: branding?.widget_url.indexOf('copilotstudio') >= 0 ? window.innerWidth > 600 ? "105px" :"-5px": window.innerWidth > 600 ? "99px" : "-4px"}}>-</a> 
                                        <a title="New Window" aria-label='Widget (opens in a new tab)' onClick={toggleWidgetOpen} style={{ position: "fixed",    cursor: "pointer",
                                            visibility: showWidget ? "visible" : 'hidden',
                                            right: branding?.widget_url.indexOf('copilotstudio') >= 0 ? "8px" : "24px", 
                                            top: window.innerWidth > 600 ? "106px" : "0px"
                                         }}><img src={NewWindowIcon}></img></a>
                                        <iframe aria-label="Widget"  title="Widget"                         
                                            src={branding?.widget_url}
                                            // src={"http://127.0.0.1:50505/"}
                                            width={"100%"}
                                            height={"100%"}
                                        ></iframe></div> 
                                    {/* <div style={{ display: "block", overflow: "hidden", width: showWidget ? "390px" : '0px', height: showWidget ? "568px" : '0px', position: "absolute", right: "0px", top: "200px", zIndex: '99', borderRadius: '10px' }}>
                                        <iframe src="https://copilotstudio.microsoft.com/environments/Default-a9da7604-1c69-4ade-87ae-5b5089bec8b8/bots/cr59f_vaTest/webchat?__version__=2" style={{width:'100%', height:'100%'}}></iframe>
                                    </div> */}
                            </div>
                            }
                            <div className={styles.langOverlay} style={{ visibility: showTranslate ? 'visible' : 'hidden' }}>
                            <div data-theme={isDarkMode ? "dark" : ""} className={styles.langModal}
                            style={{ visibility: showTranslate? 'visible': 'hidden'}}>
                                <Stack className={styles.modalContainer} data-theme={isDarkMode ? "dark" : ""}>
                                    <Stack horizontal verticalAlign="center" horizontalAlign="baseline" data-theme={isDarkMode ? "dark" : ""}>
                                        <div className={styles.dialogTitle} data-theme={isDarkMode ? "dark" : ""}>{"Select a language"}</div>
                                    </Stack>
                                    <Stack horizontalAlign="end" verticalAlign="center" role="button" tabIndex={0}
                                        onClick={handleTranslateDialogClose} className={closeXLangModal}>{'X'}
                                    </Stack>
                                </Stack>
                                    <Stack horizontal verticalAlign="center" horizontalAlign="baseline" data-theme={isDarkMode ? "dark" : ""}>
                                        <Stack horizontal horizontalAlign="start" data-theme={isDarkMode ? "dark" : ""}>
                                            <div data-theme={isDarkMode ? "dark" : ""} style={{ padding: '10px' }}>
                                                <GoogleTranslate darkmode={isDarkMode} />

                                            </div>
                                        </Stack>
                                    </Stack>
                                    <Stack horizontal verticalAlign="center" horizontalAlign="baseline" style={{ width: "100%" }} data-theme={isDarkMode ? "dark" : ""}>
                                        <Stack horizontal horizontalAlign="end" style={{ width: "100%" }} data-theme={isDarkMode ? "dark" : ""}>
                                            <CommandBarButton
                                                role="button"
                                                className={styles.closeButtonTranslate}
                                                onClick={handleTranslateDialogClose}
                                                text="CLOSE"
                                                aria-label="CLOSE"
                                                title="CLOSE"
                                                onKeyDown={e => e.key === "Enter" || e.key === " " ? handleTranslateDialogClose() : null}
                                            />
                                        </Stack>
                                    </Stack>
                            </div>
                          </div>
                        
                    </div>
                        {messages && messages.length > 0 && isIntentsPanelOpen && (
                            <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Intents Panel">
                                <Stack
                                    aria-label="Intents Panel Header Container"
                                    horizontal
                                    className={styles.citationPanelHeaderContainer}
                                    horizontalAlign="space-between"
                                    verticalAlign="center">
                                    <span aria-label="Intents" className={styles.citationPanelHeader}>
                                        Intents
                                    </span>
                                    <IconButton
                                        iconProps={{ iconName: 'Cancel' }}
                                        aria-label="Close intents panel"
                                        onClick={() => setIsIntentsPanelOpen(false)}
                                    />
                                </Stack>
                                <Stack horizontalAlign="space-between">
                                    {execResults.map((execResult) => {
                                        return (
                                            <Stack className={styles.exectResultList} verticalAlign="space-between">
                                                <><span>Intent:</span> <p>{execResult.intent}</p></>
                                                {execResult.search_query && <><span>Search Query:</span>
                                                    <SyntaxHighlighter
                                                        style={nord}
                                                        wrapLines={true}
                                                        lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                                                        language="sql"
                                                        PreTag="p">
                                                        {execResult.search_query}
                                                    </SyntaxHighlighter></>}
                                                {execResult.search_result && <><span>Search Result:</span> <p>{execResult.search_result}</p></>}
                                                {execResult.code_generated && <><span>Code Generated:</span>
                                                    <SyntaxHighlighter
                                                        style={nord}
                                                        wrapLines={true}
                                                        lineProps={{ style: { wordBreak: 'break-all', whiteSpace: 'pre-wrap' } }}
                                                        language="python"
                                                        PreTag="p">
                                                        {execResult.code_generated}
                                                    </SyntaxHighlighter>
                                                </>}
                                            </Stack>
                                        )
                                    })}
                                </Stack>
                            </Stack.Item>
                        )}
                     {(appStateContext?.state.isChatHistoryOpen && appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) && <ChatHistoryPanel/>}
                </Stack>
            )}
        </div>
    );
};
// #endregion


export default Chat;